import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';
import moment from 'moment';
import { mainurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import { Row, Col, Card, CardBody, Input, Button, Label } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import axios from 'axios';

const CreateMaintainence = () => {
  const [loading, setLoading] = useState(false);
  const [maintainenceDate, setMaintainenceDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [components, setComponents] = useState({ oil: [], filter: [] });
  const [oilItems, setOilItems] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [oilColumns, setOilColumns] = useState([]);
  const [filterColumns, setFilterColumns] = useState([]);
  const [parts, setParts] = useState([]);

  const { machine_id } = useParams();

  // Single useEffect for initial data fetch
  useEffect(() => {
    const fetchInitialData = async () => {
      await fetchParts();
      await fetchComponents();
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (parts.length > 0 && (components.oil.length > 0 || components.filter.length > 0)) {
      setOilColumns(createColumns(components.oil, handleChangeOilItem, parts));
      setFilterColumns(createColumns(components.filter, handleChangeFilterItem, parts));
    }
  }, [parts, components]);

  const fetchParts = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${mainurl}view_part`);
      const data = await response.json();
      console.log('Parts data received:', data?.data);
      if (data?.data) {
        setParts(data.data);
      }
    } catch (error) {
      console.error("Error fetching parts:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchComponents = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${mainurl}regular_maintenance_components`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ machine_id })
      });
      const data = await response.json();

      if (data?.data?.components) {
        const { oil, filter } = data.data.components;
        setComponents({ oil, filter });

        const initialOilItem = createInitialItem(oil);
        const initialFilterItem = createInitialItem(filter);

        setOilItems([initialOilItem]);
        setFilterItems([initialFilterItem]);
      }
    } catch (error) {
      console.error('Error fetching components:', error);
    } finally {
      setLoading(false);
    }
  };

  const createInitialItem = (components) => {
    return components.reduce((acc, component) => {
      const fieldName = component.field || component.component.toLowerCase().replace(/\s+/g, '_');
      acc[fieldName] = 'Yes';
      acc[`${fieldName}Qty`] = '';
      acc.cost = '';
      return acc;
    }, {});
  };

  const createCheckboxCell = (component, partsData) => (row, index, handleChange) => {
    const fieldName = component.field || component.component.toLowerCase().replace(/\s+/g, '_');
    const qtyField = `${fieldName}Qty`;

    console.log('Creating cell with parts data:', partsData?.length);

    return (
      <div className="d-flex align-items-center">
        <div style={{ width: '80%', marginLeft: '10px' }}>
          <Input
            type="select"
            value={row[qtyField] || ''}
            onChange={(e) => handleChange(index, qtyField, e.target.value)}
            className="form-control"
          >
            <option value="">Select Part</option>
            {Array.isArray(partsData) && partsData.map((part) => (
              <option key={part.part_id} value={part.part_no}>
                {`${part.part_no} - ${part.part_model_no}`}
              </option>
            ))}
          </Input>
        </div>
      </div>
    );
  };

  const createColumns = (components, handleChange, partsData) => {
    if (!Array.isArray(components) || !Array.isArray(partsData)) {
      console.warn('Invalid components or parts data:', { components, partsData });
      return [];
    }

    console.log('Creating columns with parts data length:', partsData.length);

    const columns = components.map(component => ({
      name: component.component,
      selector: row => row[component.field || component.component.toLowerCase().replace(/\s+/g, '_')],
      wrap: true,
      cell: (row, index) => createCheckboxCell(component, partsData)(row, index, handleChange)
    }));

    columns.push({
      name: 'COST (in Rs.)',
      selector: row => row.cost,
      cell: (row, index) => (
        <Input
          type="number"
          value={row.cost || ''}
          onChange={(e) => handleChange(index, 'cost', e.target.value)}
          className="form-control"
          style={{ width: '90%' }}
          min="0"
        />
      )
    });

    return columns;
  };

  const handleChangeOilItem = (index, field, value) => {
    setOilItems(prev => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [field]: value };
      return updated;
    });
  };

  const handleChangeFilterItem = (index, field, value) => {
    setFilterItems(prev => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [field]: value };
      return updated;
    });
  };

  const handleSubmit = async () => {
    try {
      const formatItems = (items, type, milestone) => {
        return items.reduce((acc, item) => {
          const componentDetails = [];
          const componentType = type === "oil" ? "Oil" : "Filter";

          Object.keys(item).forEach(key => {
            if (item[key] === 'Yes') {
              const baseField = key;
              const partNo = item[`${baseField}Qty`];

              if (partNo) {
                componentDetails.push({
                  milestone,
                  actual_quantity: 1,
                  part_no: partNo,
                  component_type: componentType,
                  rate: parseFloat(item.cost) || 0,
                  total_cost: parseFloat(item.cost) || 0,
                  is_done: 1
                });
              }
            }
          });

          return [...acc, ...componentDetails];
        }, []);
      };

      const milestone = 250;
      const totalCost = [...oilItems, ...filterItems].reduce((sum, item) =>
        sum + Number(item.cost || 0), 0);

      const payload = {
        machine_id,
        machine_maintenance_header: [
          {
            maintenance_type: 1,
            servicing_date: maintainenceDate,
            servicing_place: "",
            servicing_address: "",
            done_by: "",
            total_cost: totalCost,
            invoice_no: "",
            remarks: "Regular Maintenance",
            servicing_milestone: milestone
          }
        ],
        machine_maintenance_details: [
          ...formatItems(oilItems, "oil", milestone),
          ...formatItems(filterItems, "filter", milestone)
        ]
      };

      setLoading(true);
      const response = await fetch(`${mainurl}insert_regular`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Failed to save');
      let notify = notification({ message: "Maintenance data saved successfully!", type: 'success' });
      notify();
    } catch (error) {
      let notify = notification({ message: "Failed! Try Again", type: 'error' });
      notify();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <Page>
        <Row className='mb-3'>
          <Col xs={12} className='mb-3'>
            <Title pageTitle="Create Service Maintainence Information" />
          </Col>
          <Col xs={12}>
            <Card color="primary" outline className='mb-3'>
              <CardBody>
                <Row className='my-2'>
                  <Col md={4}>
                    <Label>Milestone</Label>
                    <Input type="select" required readOnly>
                      {[250, 500, 750, 1000].map(hours => (
                        <option key={hours} value={hours}>{hours} Hours</option>
                      ))}
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label>Maintainence Date</Label>
                    <Input
                      type="date"
                      value={maintainenceDate}
                      onChange={(e) => setMaintainenceDate(e.target.value)}
                    />
                  </Col>
                </Row>

                <Title pageTitle="OIL" />
                <DataTable
                  columns={oilColumns}
                  data={oilItems}
                  customStyles={custTableStyles}
                  noDataComponent={<div>No data available</div>}
                  progressPending={loading}
                />

                <Title pageTitle="FILTER" />
                <DataTable
                  columns={filterColumns}
                  data={filterItems}
                  customStyles={custTableStyles}
                  noDataComponent={<div>No data available</div>}
                  progressPending={loading}
                />

                <Button color='primary' onClick={handleSubmit}>Save</Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
      <Footer />
    </div>
  );
};

export default CreateMaintainence;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signIn } from './../component/redux/logged';
import { notification } from '../component/hocs/notification';
import { 
  Col, 
  Row, 
  Label, 
  Input, 
  Button, 
  FormGroup,
  Card,
  CardBody,
  Spinner
} from 'reactstrap';
import logo from './../assets/img/logo.png';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mounted, setMounted] = useState(false);

  const [formData, setFormData] = useState({
    userName: '',
    password: '',
    role: 'Admin',
    location: 'Ranchi'
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  const handleInputChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const login_handler = async () => {
    setLoading(true);
    
    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 800));
    
    if (formData.userName === 'admin' && formData.password === 'admin123') {
      dispatch(signIn({
        login: true,
        userName: 'admin',
        userId: 123,
        role: formData.role,
        location: formData.location,
        logData: ''
      }));
      notification({ message: 'Login successful!', type: 'success' })();
      navigate('/MachineList');
    } else {
      notification({ message: 'Invalid login credentials!', type: 'error' })();
    }
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      login_handler();
    }
  };

  return (
    <div className={`login-container ${mounted ? 'mounted' : ''}`}>
      <div className="login-overlay" />
      
      <Row className="login-content">
        <Col md={7} className="d-none d-md-flex align-items-center justify-content-center">
          <div className="welcome-section text-white">
            <h1 className="welcome-title">Welcome Back</h1>
            <p className="welcome-text">Enter your credentials to access the dashboard</p>
          </div>
        </Col>
        
        <Col md={5} className="login-form-container">
          <Card className="login-card">
            <CardBody>
              <div className="text-center mb-4">
                <img 
                  src={logo} 
                  alt="logo" 
                  className="login-logo"
                />
              </div>

              <div className="form-section">
                <div className="floating-input">
                  <Input
                    type="text"
                    id="userName"
                    className="custom-input"
                    value={formData.userName}
                    onChange={(e) => handleInputChange('userName', e.target.value)}
                    onKeyPress={handleKeyPress}
                    required
                  />
                  <Label for="userName" className="floating-label">Username</Label>
                </div>

                <div className="floating-input">
                  <Input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    className="custom-input"
                    value={formData.password}
                    onChange={(e) => handleInputChange('password', e.target.value)}
                    onKeyPress={handleKeyPress}
                    required
                  />
                  <Label for="password" className="floating-label">Password</Label>
                  <Button
                    color="link"
                    className="password-toggle"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? '🔒' : '👁️'}
                  </Button>
                </div>

                <div className="select-input">
                  <Label className="select-label">Role</Label>
                  <Input
                    type="select"
                    value={formData.role}
                    onChange={(e) => handleInputChange('role', e.target.value)}
                    className="custom-select"
                  >
                    <option>Admin</option>
                    <option>User</option>
                    <option>Manager</option>
                  </Input>
                </div>

                <div className="select-input">
                  <Label className="select-label">Location</Label>
                  <Input
                    type="select"
                    value={formData.location}
                    onChange={(e) => handleInputChange('location', e.target.value)}
                    className="custom-select"
                  >
                    <option>Ranchi</option>
                    <option>Jamshedpur</option>
                    <option>Kolkata</option>
                  </Input>
                </div>

                <Button
                  color="primary"
                  className="login-button"
                  onClick={login_handler}
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner size="sm" className="me-2" />
                  ) : (
                    'Sign In'
                  )}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
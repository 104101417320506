import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import Logo from "./../../assets/img/logo_1.png";
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import { FaHome } from "react-icons/fa";
import { MdOutlineHome } from "react-icons/md";

function Header() {
  //redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);

  const navigate = useNavigate();
  const [m1, setM1] = useState(false);
  const [m2, setM2] = useState(false);
  const [m3, setM3] = useState(false);
  const [m4, setM4] = useState(false);
  const [m5, setM5] = useState(false);
  const [m6, setM6] = useState(false);
  const [m7, setM7] = useState(false);

  useEffect(() => {
    console.log(log_data);
    if (log_data.login !== true) {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/");
    }
  }, []);

  function signOutClickHandler() {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  }

  return (
    <>
      <div className="headerPage">
        <Row>
          <Col xs={8} className="p-2">
            {/* <Col
            xs={12}
            className="d-flex align-items-center justify-content-center borderBottom"
          >
            <img height="65px" src={logo} alt="logo" />
            <h3 className="text-primary m-0">
              <b>Classic Engicon Pvt. Ltd.</b>
            </h3>
          </Col> */}
            {/* <Col xs={12} className="borderBottom"> */}
            {/* ------------------ In Laptop Menu  -------------------- */}
            <div className="justify-content-start align-items-center h-100 d-none d-md-flex">
              <div className="" style={{ height: "40px", width: "25px" }}></div>
              {/* <div className="d_menu" onClick={() => navigate("/home")}>
                Dashboard
              </div> */}
              <Dropdown
                isOpen={m1}
                onMouseEnter={() => setM1(true)}
                onMouseLeave={() => setM1(false)}
              >
                <DropdownToggle
                  className={`d_menu ${m1 && "bg-primary text-white"}`}
                  data-toggle="dropdown"
                  tag="span"
                >
                  Machine
                </DropdownToggle>
                <DropdownMenu>
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM1(false);
                    }}
                  >
                    <Link to="/MachineList">Machine List</Link>
                  </div>
                </DropdownMenu>
              </Dropdown>

              {/* <Dropdown
                isOpen={m2}
                onMouseEnter={() => setM2(true)}
                onMouseLeave={() => setM2(false)}
              >
                <DropdownToggle
                  className={`d_menu ${m2 && "bg-primary text-white"}`}
                  data-toggle="dropdown"
                  tag="span"
                >
                  Manpower
                </DropdownToggle>
                <DropdownMenu>
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM2(false);
                    }}
                  >
                    <Link to="/ManpowerList">Manpower List</Link>
                  </div>
                </DropdownMenu>
              </Dropdown> */}

              {/* <Dropdown
                isOpen={m4}
                onMouseEnter={() => setM4(true)}
                onMouseLeave={() => setM4(false)}
              >
                <DropdownToggle
                  className={`d_menu ${m4 && "bg-primary text-white"}`}
                  data-toggle="dropdown"
                  tag="span"
                >
                  Project
                </DropdownToggle>
                <DropdownMenu>
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM4(false);
                    }}
                  >
                    <Link to="/ProjectList">Project List</Link>
                  </div>
                </DropdownMenu>
              </Dropdown> */}

              <Dropdown
                isOpen={m5}
                onMouseEnter={() => setM5(true)}
                onMouseLeave={() => setM5(false)}
              >
                <DropdownToggle
                  className={`d_menu ${m5 && "bg-primary text-white"}`}
                  data-toggle="dropdown"
                  tag="span"
                >
                  Store
                </DropdownToggle>
                <DropdownMenu>
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM5(false);
                    }}
                  >
                    <Link to="/StoreList">Store List</Link>
                  </div>
                </DropdownMenu>
              </Dropdown>

              <Dropdown
                isOpen={m6}
                onMouseEnter={() => setM6(true)}
                onMouseLeave={() => setM6(false)}
              >
                <DropdownToggle
                  className={`d_menu ${m6 && "bg-primary text-white"}`}
                  data-toggle="dropdown"
                  tag="span"
                >
                  Indent
                </DropdownToggle>
                <DropdownMenu>
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM6(false);
                    }}
                  >
                    <Link to="/IndentList">Indent List</Link>
                  </div>
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM7(false);
                    }}
                  >
                    <Link to="/POList">PO List</Link>
                  </div>
                </DropdownMenu>
              </Dropdown>

              <Dropdown
                isOpen={m3}
                onMouseEnter={() => setM3(true)}
                onMouseLeave={() => setM3(false)}
              >
                <DropdownToggle
                  className={`d_menu ${m3 && "bg-primary text-white"}`}
                  data-toggle="dropdown"
                  tag="span"
                >
                  Master
                </DropdownToggle>
                <DropdownMenu>
                  {/* <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM3(false);
                    }}
                  >
                    <Link to="/item-master">Item Master</Link>
                  </div>
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM3(false);
                    }}
                  >
                    <Link to="/item-type-master">Item Type Master</Link>
                  </div>
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM3(false);
                    }}
                  >
                    <Link to="/customer-master">Customer Master</Link>
                  </div> */}
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM3(false);
                    }}
                  >
                    <Link to="/StoreMaster">Store Master</Link>
                  </div>
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM3(false);
                    }}
                  >
                    <Link to="/PartMaster">Part Master</Link>
                  </div>
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM3(false);
                    }}
                  >
                    <Link to="/SiteMaster">Site Master</Link>
                  </div>
                  {/* <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM3(false);
                    }}
                  >
                    <Link to="/uom-master">UOM Master</Link>
                  </div> */}
                  
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM3(false);
                    }}
                  >
                    <Link to="/MachineExcel">Excel Upload</Link>
                  </div>
                </DropdownMenu>
              </Dropdown>

              <div className="d_menu" onClick={() => signOutClickHandler()}>
                Signout
              </div>
            </div>
            <div className="d-flex d-md-none justify-content-start align-items-center h-100"></div>
          </Col>

          <Col xs={4} className="d-flex align-items-center justify-content-end">
            <img className="p-1 me-2" height="55px" src={Logo} alt="logo" />
            <h5 className="text-primary m-0 me-4">
              <b>Classic Engicon Pvt. Ltd</b>
            </h5>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Header;

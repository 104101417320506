import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';
import axios from 'axios';
import { mainurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import {
  Row, Col, Card, CardBody, Button, Input, InputGroupText, InputGroup,
  Modal, ModalHeader, ModalBody, Form, Label, Spinner, FormGroup
} from 'reactstrap';

import { FaEdit, FaPlusCircle, FaSearch } from 'react-icons/fa';

const paginationComponentOptions = {
  selectAllRowsItem: true,
};

const StoreMaster = () => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [storeModal, setStoreModal] = useState(false);

  // Form state
  const [storeDetails, setStoreDetails] = useState({
    store_headoffice: '',
    store_location: '',
    store_description: ''
  });

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getStoreList();
  }, []);

  const getStoreList = () => {
    setLoading(true);
    axios.get(mainurl + 'view_store')
      .then(function (response) {
        const list = response.data.data.map((row, index) => ({
          sl: index + 1,
          store_id: row.store_id,
          store_headoffice: row.store_headoffice,
          store_location: row.store_location,
          store_description: row.store_description,
          action: (
            <FaEdit
              className='cursor ms-3'
              size={20}
              color="#6da845"
              onClick={() => handleEdit(row)}
            />
          )
        }));

        setTableData(list);
        setFilterData(list);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        let notify = notification({ message: "Failed to fetch stores! Try Again", type: 'error' });
        notify();
        setLoading(false);
      });
  };

  const handleEdit = (store) => {
    setStoreDetails({
      store_headoffice: store.store_headoffice,
      store_location: store.store_location,
      store_description: store.store_description
    });
    // Add your edit logic here
  };

  const handleStoreSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Add your store creation API call here
      let notify = notification({ message: "Store Added Successfully", type: 'success' });
      notify();
      setStoreModal(false);
      getStoreList();
      // Reset form
      setStoreDetails({
        store_headoffice: '',
        store_location: '',
        store_description: ''
      });
    } catch (error) {
      let notify = notification({ message: "Failed! Try Again", type: 'error' });
      notify();
    } finally {
      setLoading(false);
    }
  };

  const filter_data_handler = (e) => {
    const query = e.target.value.toLowerCase().trim();
    const filteredData = tableData.filter(item =>
      item?.store_location?.toLowerCase().includes(query) ||
      item?.store_headoffice?.toLowerCase().includes(query) ||
      item?.store_description?.toLowerCase().includes(query)
    );
    setFilterData(filteredData);
  };

  return (
    <div>
      <Header />
      <Page>
        <Row className='mb-3'>
          <Col xs={3} className='mb-1'>
            <Title pageTitle="Store Master" />
          </Col>
          <Col md={6} className='d-flex justify-content-end align-items-center'>
            <Button
              color='primary'
              onClick={() => setStoreModal(true)}
            >
              <FaPlusCircle size={20} /> Add Store
            </Button>
          </Col>
          <Col xs={3} className='mb-1 text-end'>
            <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input
                size="sm"
                type="text"
                placeholder='Search'
                onChange={filter_data_handler}
              />
            </InputGroup>
          </Col>
        </Row>
        <Card color="primary" outline className='mb-3'>
          <CardBody>
            <Row>
              <Col md={12} className='mb-2'>
                <DataTable
                  className='mb-3'
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "50px"
                    },
                    {
                      name: 'Head Office',
                      selector: row => row.store_headoffice,
                      wrap: true
                    },
                    {
                      name: 'Location',
                      selector: row => row.store_location,
                      wrap: true
                    },
                    {
                      name: 'Description',
                      selector: row => row.store_description,
                      wrap: true,
                      gap: 2
                    },
                    {
                      name: 'Action',
                      selector: row => row.action,
                      width: "100px"
                    },
                  ]}
                  data={filterData}
                  highlightOnHover
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                  progressPending={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Modal
          centered
          scrollable
          size="lg"
          isOpen={storeModal}
          toggle={() => setStoreModal(!storeModal)}
        >
          <ModalHeader toggle={() => setStoreModal(!storeModal)}>
            Add Store
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleStoreSubmit}>
              <Row>
                <Col xs={12} className='mb-2'>
                  <Label for="store_headoffice">Head Office*</Label>
                  <Input
                    id="store_headoffice"
                    name="store_headoffice"
                    placeholder="Enter Head Office"
                    value={storeDetails.store_headoffice}
                    onChange={(e) => setStoreDetails({
                      ...storeDetails,
                      store_headoffice: e.target.value
                    })}
                    required
                  />
                </Col>
                <Col xs={12} className='mb-2'>
                  <Label for="store_location">Location*</Label>
                  <Input
                    id="store_location"
                    name="store_location"
                    placeholder="Enter Store Location"
                    value={storeDetails.store_location}
                    onChange={(e) => setStoreDetails({
                      ...storeDetails,
                      store_location: e.target.value
                    })}
                    required
                  />
                </Col>
                <Col xs={12} className='mb-2'>
                  <Label for="store_description">Description</Label>
                  <Input
                    id="store_description"
                    name="store_description"
                    type="textarea"
                    placeholder="Enter Store Description"
                    value={storeDetails.store_description}
                    onChange={(e) => setStoreDetails({
                      ...storeDetails,
                      store_description: e.target.value
                    })}
                  />
                </Col>
                <Col xs={12} className='mb-2'>
                  <b>Note:</b> (*) Mark fields are mandatory.
                </Col>
                <Col xs={12} className='mb-2 text-end'>
                  <Button
                    className='mt-2'
                    block
                    color='primary'
                    disabled={loading}
                  >
                    {loading ? 'Adding...' : 'Add Store'}
                    {loading && <Spinner size="sm" className="ms-2">Loading...</Spinner>}
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </Page>
      <Footer />
    </div>
  );
};

export default StoreMaster;
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';
import moment from 'moment';
import axios from 'axios';
import { mainurl } from '../../MainUrl';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import {
  Row, Col, Card, CardBody, Button, Input, InputGroupText, InputGroup
} from 'reactstrap';

import { FaPlusCircle, FaSearch } from 'react-icons/fa';
import { BsFillArrowRightSquareFill } from "react-icons/bs";

const paginationComponentOptions = {
  selectAllRowsItem: true,
};

const IndentList = () => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getIndentList();
  }, []);

  const getIndentList = () => {
    setLoading(true);
    axios.get(mainurl + 'view_indent')
      .then(function (response) {
        const list = response.data.data.map((row, index) => ({
          sl: index + 1,
          indent_date: moment(row.indent_date).format('DD-MM-YYYY'),
          indent_no: row.indent_no,
          created_by: row.created_by,
          indent_status: row.indent_status,
          total_quantity: row.total_quantity,
          action: (
            <BsFillArrowRightSquareFill
              className='cursor'
              onClick={() => navigate(`/IndentView/${row.indent_no}`)}
              size={25}
              color="#FFD100"
            />
          )
        }));

        setTableData(list);
        setFilterData(list);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const filter_data_handler = (e) => {
    const query = e.target.value.toLowerCase().trim();
    const filteredData = tableData.filter(item =>
      item?.created_by?.toLowerCase().includes(query) ||
      item?.indent_no?.toLowerCase().includes(query) ||
      item?.indent_status?.toLowerCase().includes(query)
    );
    setFilterData(filteredData);
  };

  return (
    <div>
      <Header />
      <Page>
        <Row className='mb-3'>
          <Col xs={6} className='mb-1'>
            <Title pageTitle="Indent List" />
          </Col>
          <Col md={3} className='text-end'>
            <Link to="/CreateIndent">
              <Button color='primary'>
                <FaPlusCircle size={20} /> Create Indent
              </Button>
            </Link>
          </Col>
          <Col xs={3} className='mb-1 text-end'>
            <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input
                size="sm"
                type="text"
                placeholder='Search'
                onChange={filter_data_handler}
              />
            </InputGroup>
          </Col>
        </Row>
        <Card color="primary" outline className='mb-3'>
          <CardBody>
            <Row>
              <Col md={12} className='mb-2'>
                <DataTable
                  className='mb-3'
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "50px"
                    },
                    {
                      name: 'Indent Date',
                      selector: row => row.indent_date,
                      wrap: true
                    },
                    {
                      name: 'Indent No',
                      selector: row => row.indent_no,
                      wrap: true
                    },
                    {
                      name: 'Created By',
                      selector: row => row.created_by,
                    },
                    {
                      name: 'Indent Status',
                      selector: row => row.indent_status,
                      wrap: true,
                    },
                    {
                      name: 'Total Quantity',
                      selector: row => row.total_quantity,
                      format: row => parseFloat(row.total_quantity).toFixed(2)
                    },
                    {
                      name: 'Action',
                      selector: row => row.action,
                    },
                  ]}
                  data={filterData}
                  highlightOnHover
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                  progressPending={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Page>
      <Footer />
    </div>
  );
};

export default IndentList;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import moment from 'moment';
import axios from 'axios';
import { mainurl, fileurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';

import { Row, Col, Card, CardBody, Input, Button, Label, Spinner } from 'reactstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

const IndentView = () => {
    // redux (From store)
    const log_data = useSelector((state) => state.logged.loginData);
    const [loading, setLoading] = useState(false);

    // State for the indent form
    const [indentNo, setIndentNo] = useState('');
    const [indentDate, setIndentDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [items, setItems] = useState([{ itemName: '', description: '' }]);

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleAddItem = () => {
        setItems([...items, { itemName: '', description: '' }]);
    };

    const handleRemoveItem = (index) => {
        const newItems = items.filter((_, i) => i !== index);
        setItems(newItems);
    };

    const handleChangeItem = (index, field, value) => {
        const newItems = [...items];
        newItems[index][field] = value;
        setItems(newItems);
    };

    const handleSubmit = () => {
        console.log('Submitting data:', { indentNo, indentDate, items });
        // Implement submit logic here
    };

    const columns = [
        // {
        //     name: 'Manufacturer',
        //     selector: row => row.itemName,
        //     wrap: true,
        //     cell: (row, index) => (
        //         <Input
        //             placeholder="Manufacturer Name"
        //             type="text"
        //             value={row.itemName}
        //             onChange={(e) => handleChangeItem(index, 'itemName', e.target.value)}
        //         />
        //     )
        // },
        // {
        //     name: 'Model No',
        //     selector: row => row.itemName,
        //     wrap: true,
        //     cell: (row, index) => (
        //         <Input
        //             placeholder="Model No"
        //             type="text"
        //             value={row.itemName}
        //             onChange={(e) => handleChangeItem(index, 'itemName', e.target.value)}
        //         />
        //     )
        // },
        {
            name: 'Item Type',
            selector: row => row.itemName,
            wrap: true,
            cell: (row, index) => (
                <Input
                    placeholder="Item Type"
                    type="text"
                    value={row.itemName}
                    onChange={(e) => handleChangeItem(index, 'itemName', e.target.value)}
                />
            )
        },
        {
            name: 'Part No',
            selector: row => row.description,
            wrap: true,
            cell: (row, index) => (
                <Input
                    placeholder="Part No"
                    type="text"
                    value={row.description}
                    onChange={(e) => handleChangeItem(index, 'description', e.target.value)}
                />
            )
        },
        {
            name: 'Quantity',
            selector: row => row.description,
            wrap: true,
            cell: (row, index) => (
                <Input
                    placeholder="Quantity"
                    type="number"
                    value={row.description}
                    onChange={(e) => handleChangeItem(index, 'description', e.target.value)}
                />
            )
        },
        {
            name: 'UOM',
            selector: row => row.description,
            wrap: true,
            cell: (row, index) => (
                <Input
                    placeholder="UOM"
                    type="number"
                    value={row.description}
                    onChange={(e) => handleChangeItem(index, 'description', e.target.value)}
                />
            )
        },
        {
            name: 'Actions',
            wrap: true,
            width: "150px",
            cell: (row, index) => (
                <Button color="primary" onClick={() => handleRemoveItem(index)}>
                    <MdDeleteForever size={20} /> Remove
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];

    return (
        <div>
            <Header />
            <Page>
                <Row className='mb-3'>
                    <Col xs={12} className='mb-1'>
                        <Title pageTitle="View Sale Indent" />
                    </Col>

                    <Col md={12} className='text-end mb-3'>
                        <Button color='primary' onClick={handleAddItem}>
                            <FaPlusCircle size={20} /> Add Item
                        </Button>
                    </Col>

                    <Col xs={12}>
                        <Card color="primary" outline className='mb-3'>
                            <CardBody>
                                <Row>
                                    <Col md={4} className='mb-2'>
                                        <Label>Indent Number</Label>
                                        <Input
                                            placeholder="Indent Number"
                                            type="text"
                                            value={indentNo}
                                            onChange={(e) => setIndentNo(e.target.value)}
                                        />
                                    </Col>
                                    <Col md={2} className='mb-2'>
                                        <Label>Indent Date</Label>
                                        <Input
                                            placeholder="Indent Date"
                                            type="date"
                                            value={indentDate}
                                            onChange={(e) => setIndentDate(e.target.value)}
                                        />
                                    </Col>
                                </Row>

                                <DataTable
                                    className='mb-3'
                                    columns={columns}
                                    data={items}
                                    customStyles={custTableStyles}
                                    noDataComponent={<div>No data available</div>}
                                    progressPending={loading}
                                    pagination
                                />

                                <Button className='mt-3' color='primary' onClick={handleSubmit}>
                                    Save
                                </Button>
                                <Button className='mt-3 mx-3' color='primary' onClick={handleSubmit}>
                                    Approve
                                </Button>
                                <Button className='mt-3' color='primary' onClick={handleSubmit}>
                                    Raise PO
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Page>
            <Footer />
        </div>
    );
}

export default IndentView;
import React, { useEffect, useState } from 'react';

import { useNavigate, Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import moment from 'moment';
import axios from 'axios';
import { mainurl, fileurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

import {
  Row, Col, Card, CardBody, CardTitle, CardHeader, Button, ButtonGroup, Input, InputGroupText, InputGroup, Modal, ModalHeader, ModalBody, Form, Label, Spinner, FormGroup
} from 'reactstrap';

import { FaPlus, FaEdit, FaClipboardList } from 'react-icons/fa';
import { BsFillArrowRightSquareFill } from "react-icons/bs";

const MachineView = () => {
  //redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);

  const [machineData, setMachineData] = useState();

  const [currentData, setCurrentData] = useState();
  const [currentColumn, setCurrentColumn] = useState();

  const [activeButton, setActiveButton] = useState('Service');
  const [fitnessExpiryDate, setFitnessExpiryDate] = useState();
  const [pollutionExpiryDate, setPollutionExpiryDate] = useState();
  const [roadTaxExpiryDate, setRoadTaxExpiryDate] = useState();
  const [idvAmount, setIdvAmount] = useState();
  const [insuranceExpiryDate, setInsuranceExpiryDate] = useState();

  const [serviceMaintenance, setServiceMaintenance] = useState(null);
  const [breakdownMaintenance, setBreakdownMaintenance] = useState(null);
  const [preventiveMaintenance, setPreventiveMaintenance] = useState(null);

  const [maintenanceCosts, setMaintenanceCosts] = useState({
    maintenance_type_1: { total_record: 0, total_cost: 0 },
    maintenance_type_2: { total_record: 0, total_cost: 0 },
    maintenance_type_3: { total_record: 0, total_cost: 0 }
  });

  const [fuelConsumption, setFuelConsumption] = useState({
    fuel_count: "0",
    total_cost: "0.00"
  });

  const [statusModal, setStatusModal] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [insuranceModal, setInsuranceModal] = useState(false);
  const [taxModal, setTaxModal] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const [newLocation, setNewLocation] = useState('');
  const [insuranceDetails, setInsuranceDetails] = useState({
    policy_no: '',
    insurance_company: '',
    insurance_start_date: '',
    insurance_expiry_date: '',
    idv: '',
    insured_value: ''
  });

  const [taxDetails, setTaxDetails] = useState({
    tax_type: '',
    tax_amount: '',
    start_date: '',
    expiry_date: ''
  });
  const [runKmsModal, setRunKmsModal] = useState(false);
  const [runHoursModal, setRunHoursModal] = useState(false);

  const [metricsData, setMetricsData] = useState([]);

  const [runKmsDetails, setRunKmsDetails] = useState({
    date_from: '',
    date_to: '',
    kilometer_start: '',
    kilometer_end: '',
    kilometer_run: ''
  });
  const [runHoursDetails, setRunHoursDetails] = useState({
    run_date: '',
    running_hours: '',
    start_time: '',
    end_time: ''
  });

  const [fuelModal, setFuelModal] = useState(false);
  const [fuelDetails, setFuelDetails] = useState({
    machine_id: '',
    quantity: '',
    consumption_date: moment().format('YYYY-MM-DD'),
    cost: ''
  });

  const { machine_id } = useParams();

  const navigate = useNavigate();

  const position = [22.8046, 86.2029];

  useEffect(() => {
    window.scrollTo(0, 0);
    get_list_handler();
    get_service_maintenance();
    get_breakdown_maintenance();
    get_preventive_maintenance();
    setCurrentColumn(columns1);
    getMaintenanceCosts();
    getFuelConsumption();
    fetchData();
  }, []);

  const get_list_handler = () => {
    setLoading(true);

    const requestData = {
      machine_id: machine_id
    };

    axios.post(mainurl + 'machine_all_details', requestData)
      .then(function (response) {
        // console.log(response.data);
        const machineDetails = response.data.data;
        setMachineData(machineDetails);

        const { machine_tax_details, machine_insurance_details } = machineDetails;

        const fitnessExpiryDate = machine_tax_details?.find(tax => tax.tax_type === 'Fitness')?.expiry_date || '';
        const pollutionExpiryDate = machine_tax_details?.find(tax => tax.tax_type === 'Pollution')?.expiry_date || '';
        const roadTaxExpiryDate = machine_tax_details?.find(tax => tax.tax_type === 'Road Tax')?.expiry_date || '';

        const lastInsuranceDetail = machine_insurance_details?.[machine_insurance_details.length - 1];
        const idvAmount = lastInsuranceDetail?.idv || '';
        const insuranceExpiryDate = lastInsuranceDetail?.insurance_expiry_date || '';

        setFitnessExpiryDate(fitnessExpiryDate);
        setPollutionExpiryDate(pollutionExpiryDate);
        setRoadTaxExpiryDate(roadTaxExpiryDate);
        setIdvAmount(idvAmount);
        setInsuranceExpiryDate(insuranceExpiryDate);
      })
      .catch(error => {
        console.log(error);
        // let notify = notification({ message: "Failed! Try Again", type: 'error' });
        // notify();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const fetchData = async () => {
    try {
      const today = moment();
      const fifteenDaysAgo = moment().subtract(15, 'days');

      const response = await axios.post(mainurl + 'check_machine_details', {
        machine_id: machine_id,
        date_from: fifteenDaysAgo.format('YYYY-MM-DD'),
        date_to: today.format('YYYY-MM-DD')
      });

      if (response.data?.data) {
        const { running_hours, kilometer_run, fuel_consumption } = response.data.data;

        // Create a map to store all unique dates
        const dateMap = new Map();

        // Process running hours
        running_hours.forEach(item => {
          const date = item.date;
          if (!dateMap.has(date)) {
            dateMap.set(date, {
              date,
              running_hours: [],
              kilometer_run: [],
              fuel_consumed: []
            });
          }
          dateMap.get(date).running_hours.push(parseFloat(item.hours));
        });

        // Process kilometer run
        kilometer_run.forEach(item => {
          const date = item.date;
          if (!dateMap.has(date)) {
            dateMap.set(date, {
              date,
              running_hours: [],
              kilometer_run: [],
              fuel_consumed: []
            });
          }
          dateMap.get(date).kilometer_run.push(parseFloat(item.kilometer_run));
        });

        // Process fuel consumption
        fuel_consumption.forEach(item => {
          const date = item.date;
          if (!dateMap.has(date)) {
            dateMap.set(date, {
              date,
              running_hours: [],
              kilometer_run: [],
              fuel_consumed: []
            });
          }
          dateMap.get(date).fuel_consumed.push(parseFloat(item.fuel_consumed));
        });

        // Convert map to array and create rows
        const rows = [];
        let slNo = 1;

        Array.from(dateMap.entries()).forEach(([date, data]) => {
          const maxEntries = Math.max(
            data.running_hours.length,
            data.kilometer_run.length,
            data.fuel_consumed.length
          );

          for (let i = 0; i < maxEntries; i++) {
            rows.push({
              sl_no: slNo++,
              date: moment(date).format('DD-MM-YYYY'),
              running_hours: data.running_hours[i] || '-',
              kilometer_run: data.kilometer_run[i] || '-',
              fuel_consumed: data.fuel_consumed[i] || '-'
            });
          }
        });

        // Sort rows by date (newest first) and then by sl_no
        // rows.sort((a, b) => {
        //   const dateA = moment(a.date, 'DD-MM-YYYY');
        //   const dateB = moment(b.date, 'DD-MM-YYYY');
        //   return dateB - dateA;
        // });

        setMetricsData(rows);
      }
    } catch (error) {
      console.error('Error fetching metrics:', error);
    } finally {
      setLoading(false);
    }
  };

  const getMaintenanceCosts = async () => {
    try {
      // Show loading state if needed
      setLoading(true);

      // Make API call using axios
      const response = await axios.post(mainurl + 'machine_cost', {
        machine_id: machine_id  // Get machine_id from URL params
      });

      // Check if response exists and update state
      if (response.data) {
        setMaintenanceCosts(response.data);
      }
    } catch (error) {
      // Error handling
      console.error('Error fetching maintenance costs:', error);

    } finally {
      // Hide loading state
      setLoading(false);
    }
  };

  const getFuelConsumption = async () => {
    try {
      const response = await axios.post(mainurl + 'count_fuel_consumption', {
        machine_id: machine_id
      });
      if (response.data?.data) {
        setFuelConsumption(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching fuel consumption:', error);
    }
  };

  const get_service_maintenance = () => {
    setLoading(true);

    const requestBody = {
      machine_id: machine_id,
      maintenance_type: 1,
    };

    axios.post(mainurl + "maintenance_list", requestBody)
      .then(function (response) {
        const list = response.data.data.maintenance_records.map((row, index) => ({
          sl: index + 1,
          servicing_date: moment(row?.servicing_date ?? "").format("DD-MM-YYYY"),
          milestone: row?.milestone ?? "",
          total_cost: row?.total_cost ?? "",
          remarks: row?.remarks ?? "",
          done_by: row?.done_by ?? "",
          action: (
            <BsFillArrowRightSquareFill
              className="cursor"
              onClick={() => navigate(`/MaintainenceList/${machine_id}`)}
              size={25}
              color="#FFD100"
            />
          ),
        }));

        setServiceMaintenance(list);
        // Only set current data if service tab is active
        if (activeButton === 'Service') {
          setCurrentData(list);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // Similar updates for breakdown maintenance
  const get_breakdown_maintenance = () => {
    setLoading(true);

    const requestBody = {
      machine_id: machine_id,
      maintenance_type: 3,
    };

    axios.post(mainurl + "maintenance_list", requestBody)
      .then(function (response) {
        const list = response.data.data.maintenance_records.map((row, index) => ({
          sl: index + 1,
          servicing_date: moment(row?.servicing_date ?? "").format("DD-MM-YYYY"),
          milestone: row?.milestone ?? "",
          total_cost: row?.total_cost ?? "",
          remarks: row?.remarks ?? "",
          done_by: row?.done_by ?? "",
          action: (
            <BsFillArrowRightSquareFill
              className="cursor"
              onClick={() => navigate(`/OtherMaintainenceList/${row.maintenance_id}`)}
              size={25}
              color="#FFD100"
            />
          ),
        }));

        setBreakdownMaintenance(list);
        // Only set current data if breakdown tab is active
        if (activeButton === 'Breakdown') {
          setCurrentData(list);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // And preventive maintenance
  const get_preventive_maintenance = () => {
    setLoading(true);

    const requestBody = {
      machine_id: machine_id,
      maintenance_type: 2,
    };

    axios.post(mainurl + "maintenance_list", requestBody)
      .then(function (response) {
        const list = response.data.data.maintenance_records.map((row, index) => ({
          sl: index + 1,
          servicing_date: moment(row?.servicing_date ?? "").format("DD-MM-YYYY"),
          milestone: row?.milestone ?? "",
          total_cost: row?.total_cost ?? "",
          remarks: row?.remarks ?? "",
          done_by: row?.done_by ?? "",
          action: (
            <BsFillArrowRightSquareFill
              className="cursor"
              onClick={() => navigate(`/OtherMaintainenceList/${row.maintenance_id}`)}
              size={25}
              color="#FFD100"
            />
          ),
        }));

        setPreventiveMaintenance(list);
        if (activeButton === 'Preventive') {
          setCurrentData(list);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleStatusSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${mainurl}update_machine_status`, {
        machine_id: machine_id,
        status_id: newStatus,
        date: new Date().toISOString()
      });

      if (response.data?.message) {
        let notify = notification({ message: 'Status updated successfully', type: 'success' });
        notify();
        setStatusModal(false);
        get_list_handler(); // Refresh data
      }
    } catch (error) {
      let notify = notification({ message: 'Failed to update status', type: 'error' });
      notify();
    }
    setLoading(false);
  };

  const handleLocationSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${mainurl}update_machine`, {
        machine_id: machine_id,
        new_location: newLocation
      });

      if (response.data?.message) {
        let notify = notification({ message: 'Location updated successfully', type: 'success' });
        notify();
        setLocationModal(false);
        get_list_handler(); // Refresh data
      }
    } catch (error) {
      let notify = notification({ message: 'Failed to update location', type: 'error' });
      notify();
    }
    setLoading(false);
  };

  const handleInsuranceSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${mainurl}insert_insurance`, {
        machine_id: machine_id,
        ...insuranceDetails
      });

      if (response.data?.message) {
        let notify = notification({ message: 'Insurance details added successfully', type: 'success' });
        notify();
        setInsuranceModal(false);
        get_list_handler(); // Refresh data
      }
    } catch (error) {
      let notify = notification({ message: 'Failed to add insurance details', type: 'error' });
      notify();
    }
    setLoading(false);
  };

  const handleTaxSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${mainurl}insert_tax`, {
        machine_id: machine_id,
        ...taxDetails
      });

      if (response.data?.message) {
        let notify = notification({ message: 'Tax details added successfully', type: 'success' });
        notify();
        setTaxModal(false);
        get_list_handler(); // Refresh data
      }
    } catch (error) {
      let notify = notification({ message: 'Failed to add tax details', type: 'error' });
      notify();
    }
    setLoading(false);
  };

  const handleRunKmsSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${mainurl}insert_run_kms`, {
        machine_id,
        ...runKmsDetails
      });

      if (response.data?.message) {
        let notify = notification({ message: 'Running kilometers added successfully', type: 'success' });
        notify();
        setRunKmsModal(false);
        get_list_handler();
      }
    } catch (error) {
      let notify = notification({ message: 'Failed to add running kilometers', type: 'error' });
      notify();
    }
    setLoading(false);
  };

  const handleRunHoursSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${mainurl}insert_run_hours`, {
        machine_id,
        ...runHoursDetails
      });

      if (response.data?.message) {
        let notify = notification({ message: 'Running hours added successfully', type: 'success' });
        notify();
        setRunHoursModal(false);
        get_list_handler();
      }
    } catch (error) {
      let notify = notification({ message: 'Failed to add running hours', type: 'error' });
      notify();
    }
    setLoading(false);
  };

  const handleFuelSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(mainurl + 'insert_fuel_consumption', {
        ...fuelDetails,
        machine_id: machine_id,
        uom_id: 2,
      });

      if (response.data?.message) {
        let notify = notification({ message: 'Fuel consumption added successfully', type: 'success' });
        notify();
        setFuelModal(false);
        // Reset form
        setFuelDetails({
          machine_id: '',
          quantity: '',
          consumption_date: moment().format('YYYY-MM-DD'),
          cost: ''
        });
      }
    } catch (error) {
      let notify = notification({ message: 'Failed to add fuel consumption', type: 'error' });
      notify();
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const isExpired = (expiryDate) => {
    const today = new Date();
    const expiry = new Date(expiryDate);
    return expiry < today;
  };

  const labels = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  const dataBar = {
    labels,
    datasets: [
      {
        label: 'Machine Utilization',
        data: [8, 2, 10, 0, 8, 8, 0],
        backgroundColor: [
          'rgba(255, 99, 132, 0.7)',
          'rgba(255, 159, 64, 0.7)',
          'rgba(255, 205, 86, 0.7)',
          'rgba(75, 192, 192, 0.7)',
          'rgba(54, 162, 235, 0.7)',
          'rgba(153, 102, 255, 0.7)',
          'rgba(201, 203, 207, 0.7)'],
      },
    ],
  };

  delete L.Icon.Default.prototype._getIconUrl;
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });

  const columns1 = [
    {
      name: 'Sl No',
      selector: (row, index) => index + 1,
      width: '80px',
    },
    {
      name: 'Date',
      selector: row => row.servicing_date,
    },
    {
      name: 'Milestone',
      selector: row => row.milestone,
    },
    {
      name: 'Expenses',
      selector: row => row.total_cost,
    },
    {
      name: 'Remarks',
      selector: row => row.remarks,
    },
    {
      name: 'Done By',
      selector: row => row.done_by,
    },
    {
      name: 'Action',
      selector: row => row.action,
      // cell: row => <BsFillArrowRightSquareFill className='cursor' onClick={() => handleNavigate(row)} size={25} color="#FFD100" />,
    }
  ];

  const columns2 = [
    {
      name: 'Sl No',
      selector: (row, index) => index + 1,
      width: '80px',
    },
    {
      name: 'Date',
      selector: row => row.servicing_date,
    },
    {
      name: 'Milestone',
      selector: row => row.milestone,
    },
    {
      name: 'Expenses',
      selector: row => row.total_cost,
    },
    {
      name: 'Remarks',
      selector: row => row.remarks,
    },
    {
      name: 'Done By',
      selector: row => row.done_by,
    },
    {
      name: 'Action',
      selector: row => row.action,
      // cell: row => <BsFillArrowRightSquareFill className='cursor' onClick={() => handleNavigate(row)} size={25} color="#FFD100" />,
    }
  ];

  const columns3 = [
    {
      name: 'Sl No',
      selector: (row, index) => index + 1,
      width: '80px',
    },
    {
      name: 'Date',
      selector: row => row.servicing_date,
    },
    {
      name: 'Milestone',
      selector: row => row.milestone,
    },
    {
      name: 'Expenses',
      selector: row => row.total_cost,
    },
    {
      name: 'Remarks',
      selector: row => row.remarks,
    },
    {
      name: 'Done By',
      selector: row => row.done_by,
    },
    {
      name: 'Action',
      selector: row => row.action,
      // cell: row => <BsFillArrowRightSquareFill className='cursor' onClick={() => handleNavigate(row)} size={25} color="#FFD100" />,
    }
  ];

  const optionstack = {
    scales: {
      xAxes: [{
        stacked: true
      }],
      yAxes: [{
        stacked: true
      }]
    }
  };

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);

    switch (buttonType) {
      case 'Service':
        setCurrentData(serviceMaintenance || []);
        setCurrentColumn(columns1);
        break;
      case 'Breakdown':
        setCurrentData(breakdownMaintenance || []);
        setCurrentColumn(columns2);
        break;
      case 'Preventive':
        setCurrentData(preventiveMaintenance || []);
        setCurrentColumn(columns3);
        break;
      default:
        setCurrentData([]);
        setCurrentColumn(columns1);
    }
  };

  const handleNavigate = (row) => {
    if (row.action === 'Maintenance') {
      navigate(`/MaintainenceList/${machine_id}`);
    } else if (row.action === 'Breakdown') {
      navigate(`/OtherMaintainenceList/${row.maintenance_id}`);
    } else if (row.action === 'Preventive') {
      navigate(`/OtherMaintainenceList/${row.maintenance_id}`);
    }
  };


  return (
    <div>
      <Header />
      <Page>
        <Row>
          <Col xs={12} className='mb-3'>
            <Title pageTitle="Machine Details" />
          </Col>
          <Col xs={9}>
            <Card color="primary" outline className='mb-3'>
              <CardHeader>Machine Information</CardHeader>
              <CardBody>
                <Row>
                  {/* {machineData ? ( */}
                  <>
                    <Label sm={3} > <b>Model: </b> </Label>
                    <Label sm={3} > {machineData?.model_header?.model ?? ''} </Label>

                    <Label sm={3} > <b>Registration Number: </b> </Label>
                    <Label sm={3} > {machineData?.registration_number ?? ''} </Label>

                    <Label sm={3} > <b>Machine Type: </b> </Label>
                    <Label sm={3} > {machineData?.machine_type ?? ''} </Label>

                    <Label sm={3} > <b>Engine Number: </b> </Label>
                    <Label sm={3} > {machineData?.engine_number ?? ''} </Label>

                    <Label sm={3} > <b>Chassis Number:</b> </Label>
                    <Label sm={3} > {machineData?.chassis_number ?? ''} </Label>

                    <Label sm={3} > <b>Transmission Serial No:</b> </Label>
                    <Label sm={3} > {machineData?.transmission_number ?? ''} </Label>

                    <Label sm={3} > <b>Status: </b> </Label>
                    <Label sm={3} > {machineData?.machine_status?.status ?? ''} </Label>

                    <Label sm={3} > <b>Service Meter Unit(SMU): </b> </Label>
                    <Label sm={3}>
                      <Link to={`/RunningList/${machine_id}`}> {machineData?.smu ?? ''} </Link>
                    </Label>

                    <Label sm={3} > <b>Location: </b> </Label>
                    <Label sm={3} > {machineData?.location ?? ''} </Label>
                  </>
                  {/* ) : (
                    <Label sm={12}>Loading machine information...</Label>
                  )} */}
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs={3}>
            <Card color="primary" outline className='mb-3'>
              <CardBody>
                <MapContainer center={position} zoom={13} style={{ width: '100%', height: '200px' }}>
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  // attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker position={position}>
                    <Popup>
                      <b>Machine Location</b> <br /> {machineData?.location ?? ''}
                    </Popup>
                  </Marker>
                </MapContainer>
              </CardBody>
            </Card>
          </Col>
          <Col xs={9}>

            <Card color="primary" outline className='mb-3'>
              <CardHeader>Other Information</CardHeader>
              <CardBody>
                <Row>
                  <Label sm={3} > <b>Date of Commissioning: </b> </Label>
                  <Label sm={3} > {formatDate(machineData?.date_of_commissioning ?? '')} </Label>

                  <Label sm={3} > <b>Date of Purchase: </b> </Label>
                  <Label sm={3} > {formatDate(machineData?.purchase_date ?? '')} </Label>

                  <Label sm={3} > <b>Invoice Date: </b> </Label>
                  <Label sm={3} > {formatDate(machineData?.invoice_date ?? '')} </Label>

                  <Label sm={3} > <b>Invoice Value: </b> </Label>
                  <Label sm={3} > Rs. {machineData?.invoice_value ?? ''} </Label>

                  <Label sm={3} > <b>Warranty: </b> </Label>
                  <Label sm={3} > {machineData?.warranty ?? 0} Years </Label>

                  <Label sm={3} > <b>Warranty Status: </b> </Label>
                  <Label sm={3} > {machineData?.warranty_status ?? ''} </Label>

                  <Label sm={3} > <b>Total Km Run: </b> </Label>
                  <Label sm={3} > {machineData?.kilometer_run ?? 0} </Label>

                  <Label sm={3} > <b>Total Run Hours: </b> </Label>
                  <Label sm={3} > {machineData?.running_hours ?? 0} </Label>

                  {/* <Label sm={3} > <b>Next Maintenance: </b> </Label>
                  <Label sm={3} > 8 December 2024 </Label> */}
                  <hr />

                  {/* Tax Information Section */}
                  <CardTitle className="text-primary">Tax Information</CardTitle>

                  <Label sm={3}> <b>Road Tax Expiry Date: </b> </Label>
                  <Label sm={3}> {formatDate(roadTaxExpiryDate)} </Label>

                  <Label sm={3}> <b>Insurance Expiry Date: </b> </Label>
                  <Label sm={3}> {formatDate(insuranceExpiryDate)} </Label>

                  <Label sm={3}> <b>Fitness Expiry Date: </b> </Label>
                  <Label sm={3}> {formatDate(fitnessExpiryDate)} </Label>

                  <Label sm={3}> <b>Pollution Expiry Date: </b> </Label>
                  <Label sm={3}> {formatDate(pollutionExpiryDate)} </Label>

                  <Label sm={3}> <b>IDV Value: </b> </Label>
                  <Label sm={3}> Rs. {idvAmount} </Label>



                  <hr />
                  <CardTitle className="text-primary">Maintenance Information</CardTitle>

                  <Label sm={3} > <b>Regular Maintenance: </b> </Label>
                  <Label sm={3}>
                    Rs. {maintenanceCosts.maintenance_type_1.total_cost.toLocaleString('en-IN', {
                      maximumFractionDigits: 2
                    })} ({maintenanceCosts.maintenance_type_1.total_record})
                  </Label>

                  <Label sm={3} > <b>Breakdown Maintainence: </b> </Label>
                  <Label sm={3}>
                    Rs. {maintenanceCosts.maintenance_type_3.total_cost.toLocaleString('en-IN', {
                      maximumFractionDigits: 2
                    })} ({maintenanceCosts.maintenance_type_3.total_record})
                  </Label>

                  <Label sm={3} > <b>Preventive Maintainence (Repair before Failure): </b> </Label>
                  <Label sm={3}>
                    Rs. {maintenanceCosts.maintenance_type_2.total_cost.toLocaleString('en-IN', {
                      maximumFractionDigits: 2
                    })} ({maintenanceCosts.maintenance_type_2.total_record})
                  </Label>

                  <Label sm={3}> <b>Fuel Consumption: </b> </Label>
                  <Label sm={3}> {machineData?.fuel_consumption ?? 0} </Label>

                  <Label sm={3}> <b>Engine Overhaul: </b> </Label>
                  <Label sm={3}> No </Label>

                  {/* <Label sm={3} > <b>Lubracant Cost: </b> </Label>
                  <Label sm={3} > Rs. 1500 </Label>

                  <Label sm={3} > <b>Fuel Cost: </b> </Label>
                  <Label sm={3} > Rs. 5000 </Label>

                  <Label sm={3} > <b>Electricity Cost: </b> </Label>
                  <Label sm={3} > Rs. 800 </Label>

                  <Label sm={3} > <b>Labour Cost: </b> </Label>
                  <Label sm={3} > Rs. 2400  </Label>

                  <Label sm={3} > <b>Breakdown Cost: </b> </Label>
                  <Label sm={3} > Rs. 9000  </Label>

                  <Label sm={3} > <b>MOR(Major Overhaul Cost): </b> </Label>
                  <Label sm={3} > Rs. 12400  </Label>

                  <Label sm={3} > <b>Running Maintenance Hrs: </b> </Label>
                  <Label sm={3} > Rs. 12000  </Label> */}

                  <hr />

                  <CardTitle className="text-primary">OEM Recommendation Overhaul</CardTitle>
                  <Col xs={12}>
                    <ul>
                      <li>If your equipment operates in a harsh or unusual environment, it may require more frequent maintenance than recommended by the OEM. For example, equipment operating in a dusty environment may require more frequent air filter changes.</li>
                      <li>If your equipment is used more frequently than the average user, it may require more frequent maintenance. Conversely, if the equipment is used less frequently than the average user, you may be able to reduce the frequency of maintenance.</li>

                    </ul>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col xs={3}>

            <Card color="primary" outline className='mb-3'>
              <CardBody>
                <Row>
                  <Col md={12} className='mb-2'>
                    <img className='img-fluid' src={require('./../../assets/img/mac.jpg')} alt="Machine Img" />
                  </Col>
                </Row>
              </CardBody>
            </Card>

            {/* <Button className='mb-3' color='primary' block  > <FaEdit /> Edit Machine Information  </Button>

            <Button className='mb-3' color='primary' block  > <FaEdit /> Edit Other Information  </Button>

            <Button className='mb-3' color='primary' block  > <FaEdit /> Edit Location Information </Button> */}

            <Button className='mb-3' color='primary' block onClick={() => setStatusModal(true)}>
              <FaEdit /> Update Machine Status
            </Button>

            <Button className='mb-3' color='primary' block onClick={() => setLocationModal(true)}>
              <FaEdit /> Update Location
            </Button>

            <Button className='mb-3' color='primary' block onClick={() => setInsuranceModal(true)}>
              <FaEdit /> Add Insurance Details
            </Button>

            <Button className='mb-3' color='primary' block onClick={() => setTaxModal(true)}>
              <FaEdit /> Add Tax Details
            </Button>

            <Button className='mb-3' color='primary' block onClick={() => setRunKmsModal(true)}>
              <FaEdit /> Add Kms Run
            </Button>

            <Button className='mb-3' color='primary' block onClick={() => setRunHoursModal(true)}>
              <FaEdit /> Add Hours Run
            </Button>

            <Button className='mb-3' color='primary' block onClick={() => setFuelModal(true)}>
              <FaPlus /> Add Fuel Consumption
            </Button>

            <Link to={`/CreateMaintainence/${machine_id}`} style={{ textDecoration: 'none' }}><Button className='mb-3' color='primary' block  > <FaPlus /> ADD Service Maintenance Information  </Button></Link>

            <Link to={`/CreateOtherMaintainence/${machine_id}`} style={{ textDecoration: 'none' }}><Button className='mb-3' color='primary' block  > <FaPlus /> ADD Breakdown Maintenance Information  </Button></Link>

            <Link to={`/CreateOtherMaintainence/${machine_id}`} style={{ textDecoration: 'none' }}><Button className='mb-3' color='primary' block  > <FaPlus /> ADD Preventive Maintenance Information  </Button></Link>

            {/* <Button className='mb-3' color='primary' block  > <FaPlus /> ADD Output Details  </Button> */}

            <Link to={`/CreateConsumption/${machine_id}`} style={{ textDecoration: 'none' }}><Button className='mb-3' color='primary' block  > <FaPlus /> ADD Regular Consumption  </Button></Link>

            <Card color="primary" outline className='mb-3'>
              <CardHeader>Last Week Utilization</CardHeader>
              <CardBody>
                <Row>
                  <Col md={12} className='mb-2'>
                    <Line options={optionstack} data={dataBar} />
                  </Col>
                </Row>
              </CardBody>
            </Card>

          </Col>
          <Col xs={12}>
            <Card color="primary" outline className='mb-3'>
              <CardHeader>Maintainence Metrics</CardHeader>
              <CardBody>

                <ButtonGroup className="mb-3 w-100">
                  <Button color={activeButton === 'Service' ? 'primary' : 'light'} onClick={() => { handleButtonClick('Service'); setActiveButton('Service'); }}>Service Maintenance (Regular)</Button>
                  <Button color={activeButton === 'Breakdown' ? 'primary' : 'light'} onClick={() => { handleButtonClick('Breakdown'); setActiveButton('Breakdown'); }}>Breakdown Repair</Button>
                  <Button color={activeButton === 'Preventive' ? 'primary' : 'light'} onClick={() => { handleButtonClick('Preventive'); setActiveButton('Preventive'); }}>Preventive Maintenance</Button>
                </ButtonGroup>

                <DataTable
                  columns={currentColumn}
                  data={currentData}
                  customStyles={custTableStyles}
                  noDataComponent={
                    <div style={{
                      padding: "24px",
                      textAlign: "center",
                      background: "#f8f9fa",
                      color: "#6c757d"
                    }}>
                      No {activeButton} maintenance records found
                    </div>
                  }
                />
              </CardBody>
            </Card>
          </Col>
          <Col xs={12}>
            <Card color="primary" outline className='mb-3'>
              <CardHeader>Machine Metrics (last 15 days)</CardHeader>
              <CardBody>
                <DataTable
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl_no,
                      width: '80px'
                    },
                    {
                      name: 'Date',
                      selector: row => row.date,
                    },
                    {
                      name: 'Running Hours',
                      selector: row => row.running_hours,
                      format: row => typeof row.running_hours === 'number' ? row.running_hours.toFixed(2) : row.running_hours
                    },
                    {
                      name: 'Kilometers Run',
                      selector: row => row.kilometer_run,
                      format: row => typeof row.kilometer_run === 'number' ? row.kilometer_run.toFixed(2) : row.kilometer_run
                    },
                    {
                      name: 'Fuel Consumption',
                      selector: row => row.fuel_consumed,
                      format: row => typeof row.fuel_consumed === 'number' ? row.fuel_consumed.toFixed(2) : row.fuel_consumed
                    }
                  ]}
                  data={metricsData}
                  customStyles={custTableStyles}
                  pagination
                  progressPending={loading}
                  progressComponent={<div className="p-4">Loading...</div>}
                  noDataComponent={<div className="p-4">No metrics data found</div>}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
      <Modal isOpen={statusModal} toggle={() => setStatusModal(!statusModal)}>
        <ModalHeader toggle={() => setStatusModal(!statusModal)}>Update Machine Status</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleStatusSubmit}>
            <FormGroup>
              <Label>Status</Label>
              <Input
                type="select"
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}
                required
              >
                <option value="">Select Status</option>
                <option value="1">Running</option>
                <option value="2">Idle</option>
                <option value="3">Under Maintenance</option>
                <option value="4">Retired</option>
              </Input>
            </FormGroup>
            <Button color="primary" type="submit" disabled={loading}>
              {loading ? 'Updating...' : 'Update Status'}
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      {/* Location Update Modal */}
      <Modal isOpen={locationModal} toggle={() => setLocationModal(!locationModal)}>
        <ModalHeader toggle={() => setLocationModal(!locationModal)}>Update Machine Location</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleLocationSubmit}>
            <FormGroup>
              <Label>Location</Label>
              <Input
                type="text"
                value={newLocation}
                onChange={(e) => setNewLocation(e.target.value)}
                placeholder="Enter new location"
                required
              />
            </FormGroup>
            <Button color="primary" type="submit" disabled={loading}>
              {loading ? 'Updating...' : 'Update Location'}
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      {/* Insurance Details Modal */}
      <Modal isOpen={insuranceModal} toggle={() => setInsuranceModal(!insuranceModal)}>
        <ModalHeader toggle={() => setInsuranceModal(!insuranceModal)}>Add Insurance Details</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleInsuranceSubmit}>
            <FormGroup>
              <Label>Policy Number</Label>
              <Input
                type="text"
                value={insuranceDetails.policy_no}
                onChange={(e) => setInsuranceDetails({ ...insuranceDetails, policy_no: e.target.value })}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Insurance Company</Label>
              <Input
                type="text"
                value={insuranceDetails.insurance_company}
                onChange={(e) => setInsuranceDetails({ ...insuranceDetails, insurance_company: e.target.value })}
                required
              />
            </FormGroup>
            {/* <FormGroup>
              <Label>Insurance Type</Label>
              <Input
                type="select"
                value={insuranceDetails.insurance_type}
                onChange={(e) => setInsuranceDetails({ ...insuranceDetails, insurance_type: e.target.value })}
                required
              >
                <option value="">Select Type</option>
                <option value="Comprehensive">Comprehensive</option>
                <option value="Third Party">Third Party</option>
              </Input>
            </FormGroup> */}
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Start Date</Label>
                  <Input
                    type="date"
                    value={insuranceDetails.insurance_start_date}
                    onChange={(e) => setInsuranceDetails({ ...insuranceDetails, insurance_start_date: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Expiry Date</Label>
                  <Input
                    type="date"
                    value={insuranceDetails.insurance_expiry_date}
                    onChange={(e) => setInsuranceDetails({ ...insuranceDetails, insurance_expiry_date: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>IDV Amount</Label>
                  <Input
                    type="number"
                    value={insuranceDetails.idv}
                    onChange={(e) => setInsuranceDetails({ ...insuranceDetails, idv: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Premium Amount</Label>
                  <Input
                    type="number"
                    value={insuranceDetails.insured_value}
                    onChange={(e) => setInsuranceDetails({ ...insuranceDetails, insured_value: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit" disabled={loading}>
              {loading ? 'Saving...' : 'Save Insurance Details'}
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      {/* Tax Entry Modal */}
      <Modal isOpen={taxModal} toggle={() => setTaxModal(!taxModal)}>
        <ModalHeader toggle={() => setTaxModal(!taxModal)}>Add Tax Details</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleTaxSubmit}>
            <FormGroup>
              <Label>Tax Type</Label>
              <Input
                type="select"
                value={taxDetails.tax_type}
                onChange={(e) => setTaxDetails({ ...taxDetails, tax_type: e.target.value })}
                required
              >
                <option value="">Select Tax Type</option>
                <option value="Road Tax">Road Tax</option>
                <option value="Fitness">Fitness</option>
                <option value="Pollution">Pollution</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Tax Amount</Label>
              <Input
                type="number"
                value={taxDetails.tax_amount}
                onChange={(e) => setTaxDetails({ ...taxDetails, tax_amount: e.target.value })}
                required
              />
            </FormGroup>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Start Date</Label>
                  <Input
                    type="date"
                    value={taxDetails.start_date}
                    onChange={(e) => setTaxDetails({ ...taxDetails, start_date: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Expiry Date</Label>
                  <Input
                    type="date"
                    value={taxDetails.expiry_date}
                    onChange={(e) => setTaxDetails({ ...taxDetails, expiry_date: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            {/* <FormGroup>
              <Label>Document Number</Label>
              <Input
                type="text"
                value={taxDetails.document_number}
                onChange={(e) => setTaxDetails({ ...taxDetails, document_number: e.target.value })}
                required
              />
            </FormGroup> */}
            <Button color="primary" type="submit" disabled={loading}>
              {loading ? 'Saving...' : 'Save Tax Details'}
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      <Modal isOpen={runKmsModal} toggle={() => setRunKmsModal(!runKmsModal)}>
        <ModalHeader toggle={() => setRunKmsModal(!runKmsModal)}>Add Running Kilometers</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleRunKmsSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>From Date</Label>
                  <Input
                    type="date"
                    value={runKmsDetails.date_from}
                    onChange={(e) => setRunKmsDetails({ ...runKmsDetails, date_from: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>To Date</Label>
                  <Input
                    type="date"
                    value={runKmsDetails.date_to}
                    onChange={(e) => setRunKmsDetails({ ...runKmsDetails, date_to: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Start Kilometer</Label>
                  <Input
                    type="number"
                    value={runKmsDetails.kilometer_start}
                    onChange={(e) => {
                      const kStart = parseFloat(e.target.value);
                      const kEnd = parseFloat(runKmsDetails.kilometer_end);
                      setRunKmsDetails({
                        ...runKmsDetails,
                        kilometer_start: e.target.value,
                        kilometer_run: kEnd ? (kEnd - kStart).toString() : ''
                      });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>End Kilometer</Label>
                  <Input
                    type="number"
                    value={runKmsDetails.kilometer_end}
                    onChange={(e) => {
                      const kEnd = parseFloat(e.target.value);
                      const kStart = parseFloat(runKmsDetails.kilometer_start);
                      setRunKmsDetails({
                        ...runKmsDetails,
                        kilometer_end: e.target.value,
                        kilometer_run: kStart ? (kEnd - kStart).toString() : ''
                      });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label>Kilometers Run</Label>
              <Input
                type="number"
                value={runKmsDetails.kilometer_run}
                readOnly
              />
            </FormGroup>
            <Button color="primary" type="submit" disabled={loading}>
              {loading ? 'Saving...' : 'Save Running Kilometers'}
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      {/* Running Hours Modal */}
      <Modal isOpen={runHoursModal} toggle={() => setRunHoursModal(!runHoursModal)}>
        <ModalHeader toggle={() => setRunHoursModal(!runHoursModal)}>Add Running Hours</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleRunHoursSubmit}>
            <FormGroup>
              <Label>Run Date</Label>
              <Input
                type="date"
                value={runHoursDetails.run_date}
                onChange={(e) => setRunHoursDetails({ ...runHoursDetails, run_date: e.target.value })}
                required
              />
            </FormGroup>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Start Time</Label>
                  <Input
                    type="time"
                    value={runHoursDetails.start_time}
                    onChange={(e) => setRunHoursDetails({ ...runHoursDetails, start_time: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>End Time</Label>
                  <Input
                    type="time"
                    value={runHoursDetails.end_time}
                    onChange={(e) => setRunHoursDetails({ ...runHoursDetails, end_time: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label>Running Hours</Label>
              <Input
                type="number"
                value={runHoursDetails.running_hours}
                onChange={(e) => setRunHoursDetails({ ...runHoursDetails, running_hours: e.target.value })}
                required
              />
            </FormGroup>
            <Button color="primary" type="submit" disabled={loading}>
              {loading ? 'Saving...' : 'Save Running Hours'}
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      <Modal isOpen={fuelModal} toggle={() => setFuelModal(!fuelModal)}>
        <ModalHeader toggle={() => setFuelModal(!fuelModal)}>Add Fuel Consumption</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleFuelSubmit}>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Consumption Date</Label>
                  <Input
                    type="date"
                    value={fuelDetails.consumption_date}
                    onChange={(e) => setFuelDetails({ ...fuelDetails, consumption_date: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Quantity</Label>
                  <Input
                    type="number"
                    value={fuelDetails.quantity}
                    onChange={(e) => setFuelDetails({ ...fuelDetails, quantity: e.target.value })}
                    required
                    placeholder="Enter quantity"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Cost</Label>
                  <Input
                    type="number"
                    value={fuelDetails.cost}
                    onChange={(e) => setFuelDetails({ ...fuelDetails, cost: e.target.value })}
                    required
                    placeholder="Enter cost"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit" disabled={loading}>
              {loading ? 'Saving...' : 'Save Fuel Consumption'}
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      <Footer />
    </div>
  )
}

export default MachineView;
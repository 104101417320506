import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../component/layout/Header";
import Footer from "../../component/layout/Footer";
import Page from "../../component/layout/Page";
import Title from "../../component/layout/Title";

import moment from "moment";
import axios from "axios";
import { mainurl, fileurl } from "../../MainUrl";
import { notification } from "../../component/hocs/notification";
import DataTable from "react-data-table-component";
import { custTableStyles } from "../../style/color";
import { confirm } from "../../component/utility/confirm";

import {
  Row, Col, Card, CardBody, Button, InputGroup, InputGroupText,
  Input, Modal, ModalHeader, ModalBody, Form, FormGroup, Label
} from "reactstrap";

import { FaFilePdf, FaEdit, FaClipboardList, FaSearch } from "react-icons/fa";
import { BsFillArrowRightSquareFill } from "react-icons/bs";

import { MdDeleteForever } from "react-icons/md";

const MachineList = () => {
  //redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);

  const [tableHeader, setTableHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const initialFormState = {
    serial_number: '',
    name: '',
    machine_type: '',
    status_id: '',
    engine_number: '',
    registration_number: '',
    transmission_number: '',
    invoice_number: '',
    purchase_date: '',
    running_hours: '',
    kilometer_run: '',
    smu: '',
    warranty: false,
    warranty_expiry_date: '',
    warranty_status: '',
    date_of_commissioning: '',
    invoice_value: '',
    invoice_date: '',
    ownership_id: '',
    location: ''
  };

  const [formData, setFormData] = useState(initialFormState);

  const [count, setCount] = useState({
    owned: 0,
    rented: 0,
    pollution: 0,
    insurance: 0,
    roadTax: 0,
    service: 0,
    oemWarranty: 0,
    underMaintenance: 0,
    idle: 0,
    running: 0
  });
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getDashboardCount();
    get_list_handler(1);
  }, []);

  const getManufacturerLogo = (manufacturer) => {
    // Create a mapping of manufacturers to their logo paths
    const manufacturerLogos = {
      'Tata Motors': '/images/manufacturers/tata-motors.png',
      // Add other manufacturers as needed
      // 'JCB': '/images/manufacturers/jcb.png',
      // 'Caterpillar': '/images/manufacturers/cat.png',
    };

    return manufacturerLogos[manufacturer] || '/images/manufacturers/default.png';
  };

  const get_list_handler = (type) => {
    setTableData([]);
    setFilterData([]);
    setLoading(true);

    let requestBody = { machine_status: 0, machine_type: 0, machine_alert: 0 };
    switch (type) {
      case 1: // owned machine
        requestBody.machine_type = 1;
        break;
      case 2: // rented machine
        requestBody.machine_status = 0;
        requestBody.machine_type = 2;
        requestBody.machine_alert = 0;
        break;
      case 3: // pollution expiry
        requestBody.machine_status = 1;
        requestBody.machine_type = 0;
        requestBody.machine_alert = 3;
        break;
      case 4: // insurance expiry
        requestBody.machine_status = 1;
        requestBody.machine_type = 0;
        requestBody.machine_alert = 1;
        break;
      case 5: // road tax expiry
        requestBody.machine_status = 1;
        requestBody.machine_type = 0;
        requestBody.machine_alert = 2;
        break;
      case 6: // service expiry
        requestBody.machine_status = 1;
        requestBody.machine_type = 0;
        requestBody.machine_alert = 4;
        break;
      case 7: // OEM warranty expiry
        requestBody.machine_status = 1;
        requestBody.machine_type = 0;
        requestBody.machine_alert = 5;
        break;
      case 8: // under maintenance
        requestBody.machine_status = 3;
        requestBody.machine_type = 0;
        requestBody.machine_alert = 0;
        break;
      case 9: // idle
        requestBody.machine_status = 2;
        requestBody.machine_type = 0;
        requestBody.machine_alert = 0;
        break;
      case 10: // running
        requestBody.machine_status = 1;
        requestBody.machine_type = 0;
        requestBody.machine_alert = 0;
        break;
      default:
        console.error("Invalid type");
        setLoading(false);
        return;
    }

    axios
      .post(mainurl + "machine_list", requestBody)
      .then(function (response) {
        console.log(response);
        let list = response.data.data.map((row, index) => {
          return {
            sl: index + 1,
            Reg_No: row?.registration_number ?? "",
            Model: row?.model_header?.model ?? "",
            Chassis_No: row?.chassis_number ?? "",
            Type: row?.machine_type ?? "",
            Owned_Rented: row?.machine_ownership_type?.ownership_type ?? "",
            Running_Hours: row?.running_hours ?? "",
            Location: row?.location ?? "",
            Status: row?.machine_status?.status ?? "",
            Action: (
              <>
                <BsFillArrowRightSquareFill
                  className="cursor"
                  onClick={() => navigate(`/MachineView/${row.machine_id}`)}
                  size={25}
                  color="#FFD100"
                />
              </>
            ),
          };
        });

        setTableData(list);
        setFilterData(list);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });


  };

  const getDashboardCount = async () => {
    try {
      const response = await axios.get(mainurl + "machine_counts");
      console.log(response.data.data);
      const data = response.data.data.reduce((acc, item) => {
        if (item["Own"]) acc.owned = Number(item["Own"]);
        if (item["Rental"]) acc.rented = Number(item["Rental"]);
        if (item["Pollution"]) acc.pollution = Number(item["Pollution"]);
        if (item["Insurance"]) acc.insurance = Number(item["Insurance"]);
        if (item["Road Tax"]) acc.roadTax = Number(item["Road Tax"]);
        if (item["Service"]) acc.service = Number(item["Service"]);
        if (item["OEM Warranty"]) acc.oemWarranty = Number(item["OEM Warranty"]);
        if (item["Under Maintenance"]) acc.underMaintenance = Number(item["Under Maintenance"]);
        if (item["Idle"]) acc.idle = Number(item["Idle"]);
        if (item["Running"]) acc.running = Number(item["Running"]);
        return acc;
      }, {});
      setCount(data);
    } catch (error) {
      console.log("Error fetching counts:", error);
    }
  };

  const filter_data_handler = (e) => {
    const query = e.target.value.toLowerCase();
    const filteredData = tableData.filter(
      (item) =>
        (item.Reg_No?.toLowerCase() || '').includes(query) ||
        (item.Model?.toLowerCase() || '').includes(query) ||
        (item.Type?.toLowerCase() || '').includes(query) ||
        (item.Location?.toLowerCase() || '').includes(query) ||
        (item.Owned_Rented?.toLowerCase() || '').includes(query) ||
        (item.Status?.toLowerCase() || '').includes(query)
    );
    setFilterData(filteredData);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(mainurl + 'insert_machine', formData);
      notification.success('Success', 'Machine added successfully');
      setFormData(initialFormState);
      toggleModal();
      get_list_handler(1); // Refresh the list
    } catch (error) {
      console.error('Error adding machine:', error);
      notification.error('Error', error.response?.data?.message || 'Failed to add machine');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <Page>
        <Col xs={12}>
          <div className="d-flex justify-content-between">
            <div className="bBox">
              <div className="text-muted fs-bold">Owned Machine</div>
              <div className="fs-20 text-primary cursor" onClick={() => get_list_handler(1)}>
                <b>{count?.owned ?? 0}</b>
              </div>
            </div>
            <div className="bBox">
              <div className="text-muted fs-bold">Rented Machine</div>
              <div className="fs-20 text-primary cursor" onClick={() => get_list_handler(2)}>
                <b>{count?.rented ?? 0}</b>
              </div>
            </div>
            <div className="bBox">
              <div className="text-muted fs-bold">Pollution Expiring</div>
              <div className="fs-20 text-primary cursor" onClick={() => get_list_handler(3)}>
                <b>{count?.pollution ?? 0}</b>
              </div>
            </div>
            <div className="bBox">
              <div className="text-muted fs-bold">Insurance Expiring</div>
              <div className="fs-20 text-primary cursor" onClick={() => get_list_handler(4)}>
                <b>{count?.insurance ?? 0}</b>
              </div>
            </div>
            <div className="bBox">
              <div className="text-muted fs-bold">Road Tax Expiring</div>
              <div className="fs-20 text-primary cursor" onClick={() => get_list_handler(5)}>
                <b>{count?.roadTax ?? 0}</b>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} className="mb-3 ">
          <div className="d-flex justify-content-between">
            <div className="bBox">
              <div className="text-muted fs-bold">Fitness Expiry</div>
              <div className="fs-20 text-primary cursor" onClick={() => get_list_handler(6)}>
                <b>{count?.service ?? 0}</b>
              </div>
            </div>
            <div className="bBox">
              <div className="text-muted fs-bold">OEM Warranty Expiry</div>
              <div className="fs-20 text-primary cursor" onClick={() => get_list_handler(7)}>
                <b>{count?.oemWarranty ?? 0}</b>
              </div>
            </div>
            <div className="bBox">
              <div className="text-muted fs-bold">Under Maintainence</div>
              <div className="fs-20 text-primary cursor" onClick={() => get_list_handler(8)}>
                <b>{count?.underMaintenance ?? 0}</b>
              </div>
            </div>
            <div className="bBox">
              <div className="text-muted fs-bold">Idle Machines</div>
              <div className="fs-20 text-primary cursor" onClick={() => get_list_handler(9)}>
                <b>{count?.idle ?? 0}</b>
              </div>
            </div>
            <div className="bBox">
              <div className="text-muted fs-bold">Running Machines</div>
              <div className="fs-20 text-primary cursor" onClick={() => get_list_handler(10)}>
                <b>{count?.running ?? 0}</b>
              </div>
            </div>
          </div>
        </Col>
        <Row className="mb-3">
          <Col xs={7} className="mb-1">
            <Title pageTitle="Machine List" />
          </Col>
          <Col xs={2} className="d-flex justify-content-end align-items-center">
            <Button
              color="primary"
              // className="w-100"
              onClick={toggleModal}
            >
              Add Machine
            </Button>
          </Col>
          <Col xs={3} className="mb-1 text-end">
            <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input
                size="sm"
                type="text"
                placeholder="Search"
                onChange={filter_data_handler}
              />
            </InputGroup>
          </Col>
        </Row>

        <Card color="primary" outline className="mb-3">
          <CardBody>
            <Row>
              <Col md={12} className="mb-2">
                <DataTable
                  className="mb-3"
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: "#",
                      selector: (row) => row.sl,
                      width: "50px",
                    },
                    {
                      name: "Reg No",
                      selector: (row) => row.Reg_No,
                    },
                    {
                      name: "Model",
                      selector: (row) => row.Model,
                      wrap: true,
                    },
                    {
                      name: "Chassis No",
                      selector: (row) => row.Chassis_No,
                      wrap: true,
                    },
                    {
                      name: "Type",
                      selector: (row) => row.Type,
                      wrap: true,
                    },
                    {
                      name: "Own/Rented",
                      selector: (row) => row.Owned_Rented,
                      wrap: true,
                    },
                    {
                      name: "Running Hours",
                      selector: (row) => row.Running_Hours,
                      wrap: true,
                    },
                    {
                      name: "Location",
                      selector: (row) => row.Location,
                      wrap: true,
                    },
                    {
                      name: "Status",
                      selector: (row) => row.Status,
                      wrap: true,
                    },
                    {
                      name: "Action",
                      selector: (row) => row.Action,
                    },
                  ]}
                  data={filterData}
                  pagination
                  progressPending={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Modal isOpen={modal} toggle={toggleModal} size="lg">
          <ModalHeader toggle={toggleModal}>Add New Machine</ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label for="name">Name*</Label>
                    <Input
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="serial_number">Serial Number*</Label>
                    <Input
                      id="serial_number"
                      name="serial_number"
                      value={formData.serial_number}
                      onChange={handleInputChange}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="machine_type">Machine Type*</Label>
                    <Input
                      id="machine_type"
                      name="machine_type"
                      value={formData.machine_type}
                      onChange={handleInputChange}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label for="engine_number">Engine Number</Label>
                    <Input
                      id="engine_number"
                      name="engine_number"
                      value={formData.engine_number}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="registration_number">Registration Number</Label>
                    <Input
                      id="registration_number"
                      name="registration_number"
                      value={formData.registration_number}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="transmission_number">Transmission Number</Label>
                    <Input
                      id="transmission_number"
                      name="transmission_number"
                      value={formData.transmission_number}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label for="invoice_number">Invoice Number</Label>
                    <Input
                      id="invoice_number"
                      name="invoice_number"
                      value={formData.invoice_number}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="invoice_date">Invoice Date</Label>
                    <Input
                      type="date"
                      id="invoice_date"
                      name="invoice_date"
                      value={formData.invoice_date}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="invoice_value">Invoice Value</Label>
                    <Input
                      type="number"
                      id="invoice_value"
                      name="invoice_value"
                      value={formData.invoice_value}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="location">Location</Label>
                    <Input
                      id="location"
                      name="location"
                      value={formData.location}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="ownership_id">Ownership</Label>
                    <Input
                      type="select"
                      id="ownership_id"
                      name="ownership_id"
                      value={formData.ownership_id}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Ownership</option>
                      <option value="1">Own</option>
                      <option value="2">Rental</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <FormGroup check className="mb-3">
                    <Label check>
                      <Input
                        type="checkbox"
                        name="warranty"
                        checked={formData.warranty}
                        onChange={handleInputChange}
                      />{' '}
                      Warranty
                    </Label>
                  </FormGroup>
                </Col>
              </Row>

              {formData.warranty && (
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="warranty_expiry_date">Warranty Expiry Date</Label>
                      <Input
                        type="date"
                        id="warranty_expiry_date"
                        name="warranty_expiry_date"
                        value={formData.warranty_expiry_date}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="warranty_status">Warranty Status</Label>
                      <Input
                        id="warranty_status"
                        name="warranty_status"
                        value={formData.warranty_status}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}

              <div className="d-flex justify-content-end gap-2 mt-4">
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
                <Button color="primary" type="submit" disabled={loading}>
                  {loading ? 'Adding...' : 'Add Machine'}
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>

      </Page>

      <Footer />
    </div>
  );
};

export default MachineList;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';
import axios from 'axios';
import { mainurl } from '../../MainUrl';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import {
  Row, Col, Card, CardBody, InputGroup, InputGroupText, Input
} from 'reactstrap';

import { FaSearch } from 'react-icons/fa';

const MaintainenceList = () => {
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterPartData, setFilterPartData] = useState([]);
  const [oilColumns, setOilColumns] = useState([]);
  const [filterColumns, setFilterColumns] = useState([]);
  const [originalOilData, setOriginalOilData] = useState([]);
  const [originalFilterData, setOriginalFilterData] = useState([]);

  const { machine_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const createColumns = (components) => {
    return [
      {
        name: 'Milestone',
        selector: row => row.milestone,
        wrap: true,
      },
      ...components.map(comp => ({
        name: comp.component,
        selector: row => row[comp.component.toUpperCase()] || '-',
        wrap: true,
      }))
    ];
  };

  const processMaintenanceData = (maintenanceData, componentsList) => {
    // Get all unique milestones
    const milestones = new Set();
    
    // Process components to get unique milestones
    maintenanceData.forEach(component => {
      Object.keys(component.maintenance).forEach(milestone => {
        milestones.add(milestone);
      });
    });

    // Create rows
    const rows = Array.from(milestones).map(milestone => {
      const row = {
        milestone: milestone + " Hours"
      };

      // Add data for each component
      componentsList.forEach(comp => {
        // Find matching maintenance component (case-insensitive)
        const maintenanceComponent = maintenanceData.find(
          mc => mc.servicing_component.toUpperCase() === comp.component.toUpperCase()
        );
        
        if (maintenanceComponent?.maintenance[milestone]?.[0]) {
          const data = maintenanceComponent.maintenance[milestone][0];
          row[comp.component.toUpperCase()] = `${data.part_no} (${data.actual_quantity})`;
        } else {
          row[comp.component.toUpperCase()] = '-';
        }
      });

      return row;
    });

    return rows.sort((a, b) => parseInt(a.milestone) - parseInt(b.milestone));
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      // Fetch component lists
      const componentsResponse = await axios.post(`${mainurl}regular_maintenance_components`, {
        machine_id: machine_id
      });

      // Fetch maintenance data
      const maintenanceResponse = await axios.post(`${mainurl}regular_maintenance`, {
        machine_id: machine_id
      });

      if (componentsResponse.data?.data?.components && maintenanceResponse.data?.data?.components_type) {
        const { oil: oilComponents, filter: filterComponents } = componentsResponse.data.data.components;
        const { oil: oilMaintenance, filter: filterMaintenance } = maintenanceResponse.data.data.components_type;

        console.log('Oil Components:', oilComponents);
        console.log('Oil Maintenance:', oilMaintenance);

        // Create columns based on component lists
        const oilCols = createColumns(oilComponents);
        const filterCols = createColumns(filterComponents);
        
        setOilColumns(oilCols);
        setFilterColumns(filterCols);

        // Process maintenance data
        const oilRows = processMaintenanceData(oilMaintenance, oilComponents);
        const filterRows = processMaintenanceData(filterMaintenance, filterComponents);

        console.log('Processed Oil Rows:', oilRows);
        console.log('Processed Filter Rows:', filterRows);

        setTableData(oilRows);
        setFilterPartData(filterRows);
        setOriginalOilData(oilRows);
        setOriginalFilterData(filterRows);
      }
    } catch (error) {
      console.error("Error fetching maintenance data", error);
    } finally {
      setLoading(false);
    }
  };

  const filter_data_handler = (e) => {
    const query = e.target.value.toLowerCase();

    // Filter oil data
    const filteredOilData = originalOilData.filter(item =>
      item.milestone.toLowerCase().includes(query)
    );
    setTableData(filteredOilData);

    // Filter filter data
    const filteredFilterData = originalFilterData.filter(item =>
      item.milestone.toLowerCase().includes(query)
    );
    setFilterPartData(filteredFilterData);
  };

  return (
    <div>
      <Header />
      <Page>
        <Row className='mb-3'>
          <Col xs={9} className='mb-1'>
            <Title pageTitle="Maintenance List" />
          </Col>
          <Col xs={3} className='mb-1 text-end'>
            <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input size="sm" type="text" placeholder='Search' onChange={filter_data_handler} />
            </InputGroup>
          </Col>
        </Row>
        <Card color="primary" outline className='mb-3'>
          <CardBody>
            <Title pageTitle="OIL" />
            <Row>
              <Col md={12} className='mb-2'>
                <DataTable
                  className='mb-3'
                  customStyles={custTableStyles}
                  columns={oilColumns}
                  data={tableData}
                  progressPending={loading}
                  noDataComponent="No maintenance data available"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card color="primary" outline className='mb-3'>
          <CardBody>
            <Title pageTitle="FILTER" />
            <Row>
              <Col md={12} className='mb-2'>
                <DataTable
                  className='mb-3'
                  customStyles={custTableStyles}
                  columns={filterColumns}
                  data={filterPartData}
                  progressPending={loading}
                  noDataComponent="No maintenance data available"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Page>
      <Footer />
    </div>
  );
};

export default MaintainenceList;
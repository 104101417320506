import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import moment from 'moment';
import axios from 'axios';
import { mainurl, fileurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import { confirm } from '../../component/utility/confirm';

import {
  Row, Col, Card, CardBody, Button, Input, InputGroupText, InputGroup,
  Modal, ModalHeader, ModalBody, Form, Label, Spinner, FormGroup
} from 'reactstrap';

import { FaEdit, FaPlusCircle, FaSearch, FaMinusCircle, FaFileAlt, FaCloudDownloadAlt } from 'react-icons/fa';

const paginationComponentOptions = {
  selectAllRowsItem: true,
};

const StoreList = () => {
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [name, setName] = useState('');
  const [model, setModel] = useState('');
  const [models, setModels] = useState([]);
  const [manufacturer, setManufacturer] = useState('');
  const [quantity, setQuantity] = useState('');
  const [vehicleNo, setVehicleNo] = useState('');

  // New state variables for API integration
  const [storeId, setStoreId] = useState('');
  const [uomId, setUomId] = useState('');
  const [transactionDate, setTransactionDate] = useState(moment().format('YYYY-MM-DD'));

  const [useCustomItemName, setUseCustomItemName] = useState(false);
  const [useCustomModel, setUseCustomModel] = useState(false);
  const [useCustomManufacturer, setUseCustomManufacturer] = useState(false);

  const [storeModal, setStoreModal] = useState(false);
  const [storeOutModal, setStoreOutModal] = useState(false);
  const [storeMovementModal, setStoreMovementModal] = useState(false);

  const navigate = useNavigate();

  const [itemsData, setItemsData] = useState([
    {
      name: 'Engine Block',
      models: [
        { modelNo: 'TSEB1209', manufacturer: 'Tata Motors' },
        { modelNo: 'TSEB1210', manufacturer: 'Bharat Forge' },
      ],
    },
    {
      name: 'Air Filter',
      models: [
        { modelNo: 'BFAF9832', manufacturer: 'Bharat Forge' },
        { modelNo: 'AF1234', manufacturer: 'Tata Motors' },
      ],
    },
    {
      name: 'Transmission Gearbox',
      models: [
        { modelNo: 'MHTG4532', manufacturer: 'Mahindra & Mahindra' },
      ],
    },
    {
      name: 'Hydraulic Pump',
      models: [
        { modelNo: 'XYZH5029', manufacturer: 'Star Hydraulics' },
      ],
    },
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    get_list_handler();
  }, []);

  const get_list_handler = () => {
    let list = [
      {
        "sl": 1,
        "Name": 'Engine Block',
        "Model_No": 'TSEB1209',
        "Manufacturer": 'Tata Motors',
        "Quantity": '15',
        "action": <>
          <FaEdit className='cursor ms-3' size={20} color="#6da845" />
        </>
      },
      {
        "sl": 2,
        "Name": 'Air Filter',
        "Model_No": 'BFAF9832',
        "Manufacturer": 'Bharat Forge',
        "Quantity": '25',
        "action": <>
          <FaEdit className='cursor ms-3' size={20} color="#6da845" />
        </>
      },
      {
        "sl": 3,
        "Name": 'Transmission Gearbox',
        "Model_No": 'MHTG4532',
        "Manufacturer": 'Mahindra & Mahindra',
        "Quantity": '10',
        "action": <>
          <FaEdit className='cursor ms-3' size={20} color="#6da845" />
        </>
      },
      {
        "sl": 4,
        "Name": 'Hydraulic Pump',
        "Model_No": 'XYZH5029',
        "Manufacturer": 'Star Hydraulics',
        "Quantity": '20',
        "action": <>
          <FaEdit className='cursor ms-3' size={20} color="#6da845" />
        </>
      }
    ];

    setTableData(list);
    setFilterData(list);
  };

  const handleItemChange = (e) => {
    const selectedItem = e.target.value;
    setSelectedItem(selectedItem);

    const itemData = itemsData.find(item => item.name === selectedItem);
    if (itemData) {
      setModels(itemData.models);
      if (itemData.models.length > 0) {
        setModel(itemData.models[0].modelNo);
        setManufacturer(itemData.models[0].manufacturer);
      } else {
        setModel('');
        setManufacturer('');
      }
    }
  };

  const handleModelChange = (e) => {
    const selectedModel = e.target.value;
    setModel(selectedModel);

    const itemData = itemsData.find(item => item.name === selectedItem);
    if (itemData) {
      const modelData = itemData.models.find(m => m.modelNo === selectedModel);
      if (modelData) {
        setManufacturer(modelData.manufacturer);
      } else {
        setManufacturer('');
      }
    }
  };

  // Updated store_add_handler with API integration
  const store_add_handler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const stockInData = {
        store_id: parseInt(storeId),
        part_no: model,
        part_type: selectedItem,
        transaction_quantity: parseFloat(quantity),
        transaction_date: transactionDate,
        uom_id: parseInt(uomId)
      };

      const response = await axios.post('http://localhost:3006/api/v1/stock_in', stockInData);

      if (response.data) {
        let notify = notification({ message: "Store Added Successfully", type: 'success' });
        notify();
        setStoreModal(false);
        get_list_handler();
      }
    } catch (error) {
      let notify = notification({ message: error.response?.data?.message || "Failed! Try Again", type: 'error' });
      notify();
    } finally {
      setLoading(false);
    }
  };

  // Updated store_out_handler with API integration
  const store_out_handler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const stockOutData = {
        store_id: parseInt(storeId),
        part_no: model,
        part_type: selectedItem,
        transaction_quantity: parseFloat(quantity),
        transaction_date: transactionDate,
        uom_id: parseInt(uomId)
      };

      const response = await axios.post('http://localhost:3006/api/v1/stock_out', stockOutData);

      if (response.data) {
        let notify = notification({ message: "Store Out Successfully", type: 'success' });
        notify();
        setStoreOutModal(false);
        get_list_handler();
      }
    } catch (error) {
      let notify = notification({ message: error.response?.data?.message || "Failed! Try Again", type: 'error' });
      notify();
    } finally {
      setLoading(false);
    }
  };

  const store_movement_handler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let notify = notification({ message: "Store Movement Successful", type: 'success' });
      notify();
      setStoreMovementModal(false);
      get_list_handler();
    } catch (error) {
      let notify = notification({ message: "Failed! Try Again", type: 'error' });
      notify();
    } finally {
      setLoading(false);
    }
  };

  const filter_data_handler = (e) => {
    const query = e.target.value.toLowerCase().trim();
    const filteredData = tableData.filter(item =>
      item?.Name?.toLowerCase().includes(query) ||
      item?.Model_No?.toLowerCase().includes(query) ||
      item?.Manufacturer?.toLowerCase().includes(query)
    );
    setFilterData(filteredData);
  };

  return (
    <div>
      <Header />
      <Page>
        <Row className='mb-3'>
          <Col xs={3} className='mb-1'>
            <Title pageTitle="Store List" />
          </Col>
          <Col md={6} className='d-flex justify-content-end align-items-center'>
            <Input
              id="name"
              name="name"
              type="select"
              value={selectedItem}
              onChange={handleItemChange}
              required
              className="me-2"
              style={{ width: '200px', fontSize: '13px' }}
            >
              <option value=""> Select Store Name </option>
              {itemsData.map(item => (
                <option key={item.name} value={item.name}>{item.name}</option>
              ))}
            </Input>
            <Button className='me-2' color='primary' onClick={() => setStoreModal(true)}>
              <FaPlusCircle size={20} /> Store In
            </Button>
            <Button className='me-2' color='primary' onClick={() => setStoreOutModal(true)}>
              <FaMinusCircle size={20} /> Store Out
            </Button>
            <Button className='me-2' color='primary' onClick={() => setStoreMovementModal(true)}>
              <FaCloudDownloadAlt size={20} /> Store Movement
            </Button>
            <Link to="/StoreReport">
              <Button color='primary'>
                <FaFileAlt size={20} /> Get Report
              </Button>
            </Link>
          </Col>
          <Col xs={3} className='mb-1 text-end'>
            <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input size="sm" type="text" placeholder='Search' onChange={filter_data_handler} />
            </InputGroup>
          </Col>
        </Row>

        <Card color="primary" outline className='mb-3'>
          <CardBody>
            <Row>
              <Col md={12} className='mb-2'>
                <DataTable
                  className='mb-3'
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "50px"
                    },
                    {
                      name: 'Store Name',
                      selector: row => row.Name,
                    },
                    {
                      name: 'Item Type',
                      selector: row => row.Name,
                    },
                    {
                      name: 'Part No',
                      selector: row => row.Model_No,
                      wrap: true
                    },
                    {
                      name: 'Stock Quantity',
                      selector: row => row.Quantity,
                      wrap: true
                    },
                    {
                      name: 'Action',
                      selector: row => row.action,
                    },
                  ]}
                  data={filterData}
                  highlightOnHover
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                  progressPending={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        {/* Store In Modal */}
        <Modal centered scrollable size="lg" isOpen={storeModal} toggle={() => setStoreModal(!storeModal)}>
          <ModalHeader toggle={() => setStoreModal(!storeModal)}>
            Store In
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={store_add_handler}>
              <Row>
                <Col xs={6} className='mb-2'>
                  <Label for="store_id">Store ID*</Label>
                  <Input
                    id="store_id"
                    type="number"
                    value={storeId}
                    onChange={(e) => setStoreId(e.target.value)}
                    required
                  />
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label for="store_name">Item Type*</Label>
                  {useCustomItemName ? (
                    <Input
                      id="name"
                      name="name"
                      placeholder="Enter Item Type"
                      value={selectedItem}
                      onChange={(e) => setSelectedItem(e.target.value)}
                      required
                    />
                  ) : (
                    <Input
                      id="name"
                      name="name"
                      type="select"
                      value={selectedItem}
                      onChange={handleItemChange}
                      required
                    >
                      <option value=""> --Select Item Type-- </option>
                      {itemsData.map(item => (
                        <option key={item.name} value={item.name}>{item.name}</option>
                      ))}
                    </Input>
                  )}
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={useCustomItemName}
                        onChange={() => setUseCustomItemName(!useCustomItemName)}
                      />
                      Enter Item Type
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={6} className='mb-2'>
                  <Label for="model_no">Part No*</Label>
                  {useCustomModel ? (
                    <Input
                      id="model_no"
                      name="model_no"
                      placeholder="Enter Part Number"
                      value={model}
                      onChange={(e) => setModel(e.target.value)}
                      required
                    />
                  ) : (
                    <Input
                      id="model_no"
                      name="model_no"
                      type="select"
                      value={model}
                      onChange={handleModelChange}
                      required
                    >
                      <option value=""> --Select Part No-- </option>
                      {models.map(m => (
                        <option key={m.modelNo} value={m.modelNo}>{m.modelNo}</option>
                      ))}
                    </Input>
                  )}
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={useCustomModel}
                        onChange={() => setUseCustomModel(!useCustomModel)}
                      />
                      Enter Part No
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={6} className='mb-2'>
                  <Label for="quantity">Quantity*</Label>
                  <Input
                    id="quantity"
                    placeholder="Enter Quantity"
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    required
                  />
                </Col>

                <Col xs={6} className='mb-2'>
                  <Label for="uom_id">UOM ID*</Label>
                  <Input
                    id="uom_id"
                    type="number"
                    value={uomId}
                    onChange={(e) => setUomId(e.target.value)}
                    required
                  />
                </Col>

                <Col xs={6} className='mb-2'>
                  <Label for="transaction_date">Transaction Date*</Label>
                  <Input
                    id="transaction_date"
                    type="date"
                    value={transactionDate}
                    onChange={(e) => setTransactionDate(e.target.value)}
                    required
                  />
                </Col>

                <Col xs={12} className='mb-2'>
                  <b>Note:</b> (*) Mark fields are mandatory.
                </Col>

                <Col xs={12} className='mb-2 text-end'>
                  <Button className='mt-2' block color='primary' disabled={loading}>
                    ADD {loading && <Spinner color="secondary">Loading...</Spinner>}
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>

        {/* Store Out Modal */}
        <Modal centered scrollable size="lg" isOpen={storeOutModal} toggle={() => setStoreOutModal(!storeOutModal)}>
          <ModalHeader toggle={() => setStoreOutModal(!storeOutModal)}>
            Store Out
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={store_out_handler}>
              <Row>
                <Col xs={6} className='mb-2'>
                  <Label for="store_id">Store ID*</Label>
                  <Input
                    id="store_id"
                    type="number"
                    value={storeId}
                    onChange={(e) => setStoreId(e.target.value)}
                    required
                  />
                </Col>

                <Col xs={6} className='mb-2'>
                  <Label for="store_name">Item Type*</Label>
                  <Input
                    id="name"
                    name="name"
                    type="select"
                    value={selectedItem}
                    onChange={handleItemChange}
                    required
                  >
                    <option value=""> --Select Item Type-- </option>
                    {itemsData.map(item => (
                      <option key={item.name} value={item.name}>{item.name}</option>
                    ))}
                  </Input>
                </Col>

                <Col xs={6} className='mb-2'>
                  <Label for="model_no">Part No*</Label>
                  <Input
                    id="model_no"
                    name="model_no"
                    type="select"
                    value={model}
                    onChange={handleModelChange}
                    required
                  >
                    <option value=""> --Select Part No-- </option>
                    {models.map(m => (
                      <option key={m.modelNo} value={m.modelNo}>{m.modelNo}</option>
                    ))}
                  </Input>
                </Col>

                <Col xs={6} className='mb-2'>
                  <Label for="quantity">Quantity*</Label>
                  <Input
                    id="quantity"
                    placeholder="Enter Quantity"
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    required
                  />
                </Col>

                <Col xs={6} className='mb-2'>
                  <Label for="uom_id">UOM ID*</Label>
                  <Input
                    id="uom_id"
                    type="number"
                    value={uomId}
                    onChange={(e) => setUomId(e.target.value)}
                    required
                  />
                </Col>

                <Col xs={6} className='mb-2'>
                  <Label for="transaction_date">Transaction Date*</Label>
                  <Input
                    id="transaction_date"
                    type="date"
                    value={transactionDate}
                    onChange={(e) => setTransactionDate(e.target.value)}
                    required
                  />
                </Col>

                <Col xs={6} className='mb-2'>
                  <Label for="vehicle_no">Machine Reg No*</Label>
                  <Input
                    id="vehicle_no"
                    placeholder="Machine Reg No"
                    type="text"
                    value={vehicleNo}
                    onChange={(e) => setVehicleNo(e.target.value)}
                    required
                  />
                </Col>

                <Col xs={12} className='mb-2'>
                  <b>Note:</b> (*) Mark fields are mandatory.
                </Col>

                <Col xs={12} className='mb-2 text-end'>
                  <Button className='mt-2' block color='primary' disabled={loading}>
                    ADD {loading && <Spinner color="secondary">Loading...</Spinner>}
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>

        {/* Store Movement Modal */}
        <Modal centered scrollable size="lg" isOpen={storeMovementModal} toggle={() => setStoreMovementModal(!storeMovementModal)}>
          <ModalHeader toggle={() => setStoreMovementModal(!storeMovementModal)}>
            Store Movement
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={store_movement_handler}>
              <Row>
                <Col xs={6} className='mb-2'>
                  <Label for="store_name">Store In*</Label>
                  <Input
                    id="name"
                    name="name"
                    type="select"
                    value={selectedItem}
                    onChange={handleItemChange}
                    required
                  >
                    <option value=""> --Select Store Name-- </option>
                    {itemsData.map(item => (
                      <option key={item.name} value={item.name}>{item.name}</option>
                    ))}
                  </Input>
                </Col>

                <Col xs={6} className='mb-2'>
                  <Label for="store_name">Store Out*</Label>
                  <Input
                    id="name"
                    name="name"
                    type="select"
                    value={selectedItem}
                    onChange={handleItemChange}
                    required
                  >
                    <option value=""> --Select Store Name-- </option>
                    {itemsData.map(item => (
                      <option key={item.name} value={item.name}>{item.name}</option>
                    ))}
                  </Input>
                </Col>

                <Col xs={6} className='mb-2'>
                  <Label for="store_name">Item Type*</Label>
                  <Input
                    id="name"
                    name="name"
                    type="select"
                    value={selectedItem}
                    onChange={handleItemChange}
                    required
                  >
                    <option value=""> --Select Item Type-- </option>
                    {itemsData.map(item => (
                      <option key={item.name} value={item.name}>{item.name}</option>
                    ))}
                  </Input>
                </Col>

                <Col xs={6} className='mb-2'>
                  <Label for="model_no">Part No*</Label>
                  <Input
                    id="model_no"
                    name="model_no"
                    type="select"
                    value={model}
                    onChange={handleModelChange}
                    required
                  >
                    {models.map(m => (
                      <option key={m.modelNo} value={m.modelNo}>{m.modelNo}</option>
                    ))}
                  </Input>
                </Col>

                <Col xs={6} className='mb-2'>
                  <Label for="quantity">Quantity*</Label>
                  <Input
                    id="quantity"
                    placeholder="Quantity"
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    required
                  />
                </Col>

                <Col xs={6} className='mb-2'>
                  <Label for="uom_id">UOM ID*</Label>
                  <Input
                    id="uom_id"
                    type="number"
                    value={uomId}
                    onChange={(e) => setUomId(e.target.value)}
                    required
                  />
                </Col>

                <Col xs={12} className='mb-2'>
                  <b>Note:</b> (*) Mark fields are mandatory.
                </Col>

                <Col xs={12} className='mb-2 text-end'>
                  <Button className='mt-2' block color='primary' disabled={loading}>
                    ADD {loading && <Spinner color="secondary">Loading...</Spinner>}
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </Page>
      <Footer />
    </div>
  );
};

export default StoreList;
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';
import moment from 'moment';
import axios from 'axios';
import { mainurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import { Row, Col, Card, CardBody, Input, Button, Label } from 'reactstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

const CreateOtherMaintainence = () => {
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);
  const [parts, setParts] = useState([]);
  const [maintenanceType, setMaintenanceType] = useState('2'); // Default to Breakdown
  const [indentDate, setIndentDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [items, setItems] = useState([{
    itemName: '',
    partNo: '',
    buyFrom: '',
    quantity: '',
    unitPrice: '',
    itemTotal: ''
  }]);

  const [areas, setAreas] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [isAreasDropdownOpen, setIsAreasDropdownOpen] = useState(false);

  const { machine_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchParts();
    fetchAreas();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.areas-dropdown-container')) {
        setIsAreasDropdownOpen(false);
      }
    };

    if (isAreasDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isAreasDropdownOpen]);

  const fetchParts = async () => {
    try {
      const response = await axios.get(`${mainurl}view_part`);
      if (response.data?.data) {
        setParts(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching parts:", error);
      let notify = notification({
        message: 'Failed to fetch parts data',
        type: 'error'
      });
      notify();
    }
  };

  const fetchAreas = async () => {
    try {
      const response = await axios.get(`${mainurl}view_area`);
      if (response.data?.data) {
        setAreas(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching areas:", error);
      let notify = notification({
        message: 'Failed to fetch areas data',
        type: 'error'
      });
      notify();
    }
  };

  const handleAddItem = () => {
    setItems([...items, {
      itemName: '',
      partNo: '',
      buyFrom: '',
      quantity: '',
      unitPrice: '',
      itemTotal: ''
    }]);
  };

  const handleRemoveItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  const handleChangeItem = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;

    if (field === 'partNo') {
      const selectedPart = parts.find(part => part.part_no === value);
      if (selectedPart) {
        newItems[index].unitPrice = selectedPart.unit_price || '';
        if (newItems[index].quantity) {
          newItems[index].itemTotal = (
            parseFloat(newItems[index].quantity) * parseFloat(newItems[index].unitPrice)
          ).toFixed(2);
        }
      }
    }

    if (field === 'quantity' || field === 'unitPrice') {
      if (newItems[index].quantity && newItems[index].unitPrice) {
        newItems[index].itemTotal = (
          parseFloat(newItems[index].quantity) * parseFloat(newItems[index].unitPrice)
        ).toFixed(2);
      }
    }

    if (field === 'itemTotal') {
      newItems[index].itemTotal = value;
    }

    setItems(newItems);
  };

  const calculateTotal = () => {
    return items.reduce((sum, item) => sum + (parseFloat(item.itemTotal) || 0), 0).toFixed(2);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const payload = {
        maintenance_header: {
          machine_id: parseInt(machine_id),
          maintenance_type: parseInt(maintenanceType),
          servicing_date: indentDate,
          total_cost: calculateTotal()
        },
        servicing_areas: selectedAreas.map(areaId => ({
          area_id: areaId
        })),
        parts_details: items.map(item => ({
          part_no: item.partNo,
          buy_from: parseInt(item.buyFrom),
          actual_quantity: parseFloat(item.quantity).toFixed(2),
          rate: parseFloat(item.unitPrice).toFixed(2)
        }))
      };

      const response = await axios.post(`${mainurl}insert_maintenances`, payload);

      if (response.data?.message) {
        let notify = notification({
          message: 'Maintenance created successfully',
          type: 'success'
        });
        notify();
        navigate(-1);
      }
    } catch (error) {
      console.error("Error creating maintenance:", error);
      let notify = notification({
        message: error.response?.data?.message || 'Failed to create maintenance',
        type: 'error'
      });
      notify();
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: 'Item Type',
      selector: row => row.itemName,
      wrap: true,
      cell: (row, index) => (
        <Input
          type="select"
          value={row.itemName}
          onChange={(e) => handleChangeItem(index, 'itemName', e.target.value)}
          style={{ fontSize: '15px' }}
        >
          <option value=""> Select Item Type </option>
          <option value="Oil">Oil</option>
          <option value="Filter">Filter</option>
        </Input>
      )
    },
    {
      name: 'Part Number',
      selector: row => row.partNo,
      wrap: true,
      cell: (row, index) => (
        <Input
          type="select"
          value={row.partNo || ''}
          onChange={(e) => handleChangeItem(index, 'partNo', e.target.value)}
          style={{ fontSize: '15px' }}
        >
          <option value="">Select Part Number</option>
          {Array.isArray(parts) && parts.map(part => (
            <option key={part.part_id} value={part.part_no}>
              {part.part_no} - {part.part_model_no}
            </option>
          ))}
        </Input>
      )
    },
    {
      name: 'Buy From',
      selector: row => row.buyFrom,
      wrap: true,
      cell: (row, index) => (
        <Input
          type="select"
          value={row.buyFrom}
          onChange={(e) => handleChangeItem(index, 'buyFrom', e.target.value)}
          style={{ fontSize: '15px' }}
        >
          <option value=""> Select Buy From </option>
          <option value="1">From Stock</option>
          <option value="2">Outside</option>
        </Input>
      )
    },
    {
      name: 'Quantity',
      selector: row => row.quantity,
      wrap: true,
      cell: (row, index) => (
        <Input
          type="number"
          value={row.quantity || ''}
          onChange={(e) => handleChangeItem(index, 'quantity', e.target.value)}
          placeholder="Quantity"
        />
      )
    },
    {
      name: 'Unit Price',
      selector: row => row.unitPrice,
      wrap: true,
      cell: (row, index) => (
        <Input
          type="number"
          value={row.unitPrice || ''}
          onChange={(e) => handleChangeItem(index, 'unitPrice', e.target.value)}
          placeholder="Unit Price"
        />
      )
    },
    {
      name: 'Item Total',
      selector: row => row.itemTotal,
      wrap: true,
      cell: (row, index) => (
        <Input
          type="number"
          value={row.itemTotal || ''}
          onChange={(e) => handleChangeItem(index, 'itemTotal', e.target.value)}
          placeholder="Item Total"
          readOnly
        />
      )
    },
    {
      name: 'Actions',
      wrap: true,
      width: "150px",
      cell: (row, index) => (
        <Button color="primary" onClick={() => handleRemoveItem(index)} disabled={loading}>
          <MdDeleteForever size={20} /> Remove
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  const getSelectedAreasText = () => {
    if (selectedAreas.length === 0) return "Select Areas";
    if (selectedAreas.length === areas.length) return "All Areas Selected";
    return `${selectedAreas.length} area${selectedAreas.length !== 1 ? 's' : ''} selected`;
  };

  return (
    <div>
      <Header />
      <Page>
        <Row className='mb-3'>
          <Col xs={9} className='mb-1'>
            <Title pageTitle="Create Breakdown Maintainence" />
          </Col>
          <Col md={3} className='text-end mb-3'>
            <Button color='primary' onClick={handleAddItem} disabled={loading}>
              <FaPlusCircle size={20} /> Add Item
            </Button>
          </Col>
          <Col xs={12}>
            <Card color="primary" outline className='mb-3'>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <Label>Maintenance Type</Label>
                    <Input
                      type="select"
                      value={maintenanceType}
                      onChange={(e) => setMaintenanceType(e.target.value)}
                      style={{ fontSize: '14px' }}
                    >
                      <option value="3">Breakdown Maintenance</option>
                      <option value="2">Preventive Maintenance</option>
                    </Input>
                  </Col>
                  <Col md={4}>
                    <Label>Servicing Areas</Label>
                    <div className="position-relative areas-dropdown-container">
                      <Input
                        type="text"
                        value={getSelectedAreasText()}
                        onClick={() => setIsAreasDropdownOpen(!isAreasDropdownOpen)}
                        readOnly
                        style={{ cursor: 'pointer' }}
                      />
                      {isAreasDropdownOpen && (
                        <Card
                          className="position-absolute w-100 mt-1 shadow"
                          style={{
                            zIndex: 1000,
                            maxHeight: '200px',
                            overflowY: 'auto'
                          }}
                        >
                          <CardBody className="p-0">
                            <div
                              className="d-flex align-items-center p-2 border-bottom hover:bg-gray-100 cursor-pointer"
                              onClick={() => {
                                if (selectedAreas.length === areas.length) {
                                  setSelectedAreas([]);
                                } else {
                                  setSelectedAreas(areas.map(area => area.area_id));
                                }
                              }}
                            >
                              <Input
                                type="checkbox"
                                checked={selectedAreas.length === areas.length}
                                onChange={() => { }}
                                className="me-2"
                              />
                              <strong>Select All</strong>
                            </div>
                            {areas.map(area => (
                              <div
                                key={area.area_id}
                                className="d-flex align-items-center p-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => {
                                  const isSelected = selectedAreas.includes(area.area_id);
                                  if (isSelected) {
                                    setSelectedAreas(selectedAreas.filter(id => id !== area.area_id));
                                  } else {
                                    setSelectedAreas([...selectedAreas, area.area_id]);
                                  }
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  checked={selectedAreas.includes(area.area_id)}
                                  onChange={() => { }}
                                  className="me-2"
                                />
                                <span>{area.area}</span>
                              </div>
                            ))}
                          </CardBody>
                        </Card>
                      )}
                    </div>
                  </Col>
                  <Col md={2} className='mb-2'>
                    <Label>Maintenance Date</Label>
                    <Input
                      type="date"
                      value={indentDate}
                      onChange={(e) => setIndentDate(e.target.value)}
                    />
                  </Col>
                </Row>

                <DataTable
                  className='mb-3'
                  columns={columns}
                  data={items}
                  customStyles={custTableStyles}
                  noDataComponent={<div>No data available</div>}
                  progressPending={loading}
                />

                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="h5 mb-0">
                    Total Amount: ₹ {calculateTotal()}
                  </div>
                  <Button
                    color='primary'
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? 'Saving...' : 'Save'}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
      <Footer />
    </div>
  );
}

export default CreateOtherMaintainence;
// const availableColors = [
//   'blue',
//   'indigo',
//   'purple',
//   'pink',
//   'red',
//   'orange',
//   'yellow',
//   'green',
//   'teal',
//   'cyan',
//   'white',
//   'gray',
//   'gray-dark',
//   'primary',
//   'secondary',
//   'success',
//   'info',
//   'warning',
//   'danger',
//   'light',
//   'dark',
// ];

export const getColor = (availableColor = 'primary') => {
  if (typeof window === 'undefined') {
    return null;
  }

  const color = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(`--${availableColor}`);

  return color;
};

export const getThemeColors = () => [
  'primary',
  'secondary',
  'success',
  'info',
  'warning',
  'danger',
];

export const custTableStyles = {
  headRow: {
    style: {
      borderRadius: '3px',
      minHeight: '40px',
      color: "#FFD100",
      backgroundColor: "#404040",
    }
  },
  rows: {
    style: {
      minHeight: '40px',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      fontSize: '0.9rem',
      fontWeight: 'bold',
      minHeight: '40px',
      color: "#FFD100",
    },
  },
  cells: {
    style: {
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '8px',
      paddingRight: '5px',
    },
  },
};

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';
import axios from 'axios';
import { mainurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import {
  Row, Col, Form, Input, Label, Button, UncontrolledTooltip,
  Card, CardBody, CardHeader
} from 'reactstrap';

const paginationComponentOptions = {
  selectAllRowsItem: true,
};

const MachineExcel = () => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedGPSFile, setSelectedGPSFile] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleGPSFileChange = (e) => {
    console.log(e.target.files[0]);
    setSelectedGPSFile(e.target.files[0]);
  };

  const handleDownloadTemplate = () => {
    const link = document.createElement('a');
    link.href = '/MachineData_Template.xlsx';
    link.download = 'MachineData_Template.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleGPSDownloadTemplate = () => {
    const link = document.createElement('a');
    link.href = '/GPSData_Template.xlsx';
    link.download = 'GPSData_Template.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleStoreSubmit = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      let notify = notification({ message: "Please select a file", type: 'error' });
      notify();
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('vehicle_file', selectedFile);

    try {
      const response = await axios.post(`${mainurl}upload_excel`, formData, {
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // },
        // For debugging - log the form data
        onUploadProgress: (progressEvent) => {
          console.log('Upload Progress:', progressEvent.loaded / progressEvent.total * 100, '%');
        }
      });

      // Log the response for debugging
      console.log('Upload Response:', response);

      let notify = notification({ message: "Excel Uploaded Successfully", type: 'success' });
      notify();
    } catch (error) {
      let notify = notification({
        message: error.response?.data?.message || "Failed! Try Again",
        type: 'error'
      });
      notify();
    } finally {
      setLoading(false);
    }
  };

  const handleGPSSubmit = async (e) => {
    e.preventDefault();

    if (!selectedGPSFile) {
      let notify = notification({ message: "Please select a file", type: 'error' });
      notify();
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('daily_report_file', selectedGPSFile);

    try {
      const response = await axios.post(`${mainurl}upload_csv`, formData, {
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // },
        // For debugging - log the form data
        onUploadProgress: (progressEvent) => {
          console.log('Upload Progress:', progressEvent.loaded / progressEvent.total * 100, '%');
        }
      });

      // Log the response for debugging
      console.log('Upload Response:', response);

      let notify = notification({ message: "Excel Uploaded Successfully", type: 'success' });
      notify();
    } catch (error) {
      let notify = notification({
        message: error.response?.data?.message || "Failed! Try Again",
        type: 'error'
      });
      notify();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <Page>
        <Row className='mb-3'>
          <Col xs={3} className='mb-1'>
            <Title pageTitle="Excel Data Upload" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card className="shadow-sm">
              <CardHeader>
                <h5 className="mb-0">Upload Machine Data</h5>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleStoreSubmit}>
                  <Row className="align-items-center">
                    <Col xs={12} className="mb-2">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <Label for="excelFile" className="form-label mb-0">
                          Upload Excel File
                        </Label>
                        <Button
                          color="primary"
                          size="sm"
                          onClick={handleDownloadTemplate}
                          id="downloadTemplate"
                        >
                          Download Template
                        </Button>
                        <UncontrolledTooltip target="downloadTemplate">
                          Download example Excel template
                        </UncontrolledTooltip>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <Input
                        type="file"
                        className="form-control"
                        id="excelFile"
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                        required
                      />
                    </Col>
                    <Col xs={3}>
                      <Button
                        color="primary"
                        type="submit"
                        disabled={loading}
                      // className="w-100"
                      >
                        {loading ? 'Uploading...' : 'Upload File'}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col xs={12} md={12}>
            <Card className="shadow-sm">
              <CardHeader>
                <h5 className="mb-0">Upload GPS Data</h5>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleGPSSubmit}>
                  <Row className="align-items-center">
                    <Col xs={12} className="mb-2">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <Label for="gpsFile" className="form-label mb-0">
                          Upload CSV File
                        </Label>
                        <Button
                          color="primary"
                          size="sm"
                          onClick={handleGPSDownloadTemplate}
                          id="downloadGPSTemplate"
                        >
                          Download Template
                        </Button>
                        <UncontrolledTooltip target="downloadGPSTemplate">
                          Download example Excel template
                        </UncontrolledTooltip>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <Input
                        type="file"
                        className="form-control"
                        id="gpsFile"
                        accept=".csv"
                        onChange={handleGPSFileChange}
                        required
                      />
                    </Col>
                    <Col xs={3}>
                      <Button
                        color="primary"
                        type="submit"
                        disabled={loading}
                        // className="w-100"
                      >
                        {loading ? 'Uploading...' : 'Upload File'}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
      <Footer />
    </div>
  );
};

export default MachineExcel;
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../component/layout/Header";
import Footer from "../../component/layout/Footer";
import Page from "../../component/layout/Page";
import Title from "../../component/layout/Title";
import moment from "moment";
import axios from "axios";
import { mainurl } from "../../MainUrl";
import DataTable from "react-data-table-component";
import { custTableStyles } from "../../style/color";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  InputGroupText,
  Input,
  Label,
} from "reactstrap";

import { FaSearch } from "react-icons/fa";

const OtherMaintainenceList = () => {
  const [loading, setLoading] = useState(false);
  const [maintenanceHeader, setMaintenanceHeader] = useState({
    machine_id: '',
    maintenance_type: '',
    servicing_date: '',
    total_cost: '0.00'
  });
  const [servicingAreas, setServicingAreas] = useState([]);
  const [partsDetails, setPartsDetails] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const { maintenance_id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    getMaintenanceList();
  }, []);

  const getMaintenanceList = () => {
    setLoading(true);
    const requestBody = {
      maintenance_id: maintenance_id
    };

    axios.post(mainurl + "maintenance_details", requestBody)
      .then(function (response) {
        if (response.data) {
          console.log('API Response:', response.data);
          setMaintenanceHeader(response.data.maintenance_header);
          setServicingAreas(response.data.servicing_areas);
          setPartsDetails(response.data.parts_details);
          setFilterData(response.data.parts_details);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filterDataHandler = (e) => {
    const query = e.target.value.toLowerCase();
    const filteredData = partsDetails.filter(
      (item) => item.part_no.toLowerCase().includes(query)
    );
    setFilterData(filteredData);
  };

  const columns = [
    {
      name: "Sl No",
      selector: (row, index) => index + 1,
      width: "80px",
    },
    {
      name: "Part Number",
      selector: (row) => row.part_no,
      wrap: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.actual_quantity,
      wrap: true,
    },
    {
      name: "Rate",
      selector: (row) => row.rate,
      format: (row) => `₹${parseFloat(row.rate).toLocaleString('en-IN', { maximumFractionDigits: 2 })}`,
      wrap: true,
    },
    {
      name: "Total Amount",
      selector: (row) => parseFloat(row.actual_quantity) * parseFloat(row.rate),
      format: (row) => `₹${(parseFloat(row.actual_quantity) * parseFloat(row.rate)).toLocaleString('en-IN', { maximumFractionDigits: 2 })}`,
      wrap: true,
    },
  ];

  return (
    <div>
      <Header />
      <Page>
        <Row className="mb-3">
          <Col xs={9} className="mb-1">
            <Title pageTitle="Maintenance Details" />
          </Col>
          <Col xs={3} className="mb-1 text-end">
            <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input
                size="sm"
                type="text"
                placeholder="Search Part Number"
                onChange={filterDataHandler}
              />
            </InputGroup>
          </Col>
        </Row>

        <Card color="primary" outline className="mb-3">
          <CardHeader>Maintenance Information</CardHeader>
          <CardBody>
            <Row>
              <Label sm={3}> <b>Maintenance Type:</b> </Label>
              <Label sm={3}> {maintenanceHeader.maintenance_type} </Label>

              <Label sm={3}> <b>Service Date:</b> </Label>
              <Label sm={3}> {moment(maintenanceHeader.servicing_date).format("DD-MM-YYYY")} </Label>

              <Label sm={3}> <b>Total Cost:</b> </Label>
              <Label sm={3}> ₹{parseFloat(maintenanceHeader.total_cost).toLocaleString('en-IN', { maximumFractionDigits: 2 })} </Label>

              <Label sm={3}> <b>Service Areas:</b> </Label>
              <Label sm={3}> {servicingAreas.map(area => area.area).join(', ')} </Label>
            </Row>
          </CardBody>
        </Card>

        <Card color="primary" outline className="mb-3">
          <CardHeader>Parts Details</CardHeader>
          <CardBody>
            <DataTable
              columns={columns}
              data={filterData}
              pagination
              progressPending={loading}
              customStyles={custTableStyles}
              noDataComponent="No parts details found"
            />
          </CardBody>
        </Card>
      </Page>
      <Footer />
    </div>
  );
};

export default OtherMaintainenceList;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';
import moment from 'moment';
import axios from 'axios';
import { mainurl } from '../../MainUrl';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import {
  Row, Col, Card, CardBody, Input, InputGroupText, InputGroup
} from 'reactstrap';

import { FaSearch } from 'react-icons/fa';
import { BsFillArrowRightSquareFill } from "react-icons/bs";

const paginationComponentOptions = {
  selectAllRowsItem: true,
};

const POList = () => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getPOList();
  }, []);

  const getPOList = () => {
    setLoading(true);
    axios.get(mainurl + 'view_all_po')
      .then(function (response) {
        // Group data by PO number to combine quantities
        const groupedData = response.data.data.reduce((acc, item) => {
          if (!acc[item.po_no]) {
            acc[item.po_no] = {
              ...item,
              total_quantity: 0,
              parts: []
            };
          }
          acc[item.po_no].total_quantity += item.quantity;
          acc[item.po_no].parts.push({
            part_type: item.part_type,
            part_no: item.part_no,
            quantity: item.quantity
          });
          return acc;
        }, {});

        const list = Object.values(groupedData).map((row, index) => ({
          sl: index + 1,
          po_date: moment(row.po_date).format('DD-MM-YYYY'),
          po_no: row.po_no,
          created_by: row.created_by,
          po_status: row.po_status,
          quantity: row.total_quantity,
          action: (
            <BsFillArrowRightSquareFill
              className='cursor'
              onClick={() => navigate(`/POView/${row.po_no}`)}
              size={25}
              color="#FFD100"
            />
          )
        }));

        setTableData(list);
        setFilterData(list);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const filter_data_handler = (e) => {
    const query = e.target.value.toLowerCase().trim();
    const filteredData = tableData.filter(item =>
      item?.created_by?.toLowerCase().includes(query) ||
      item?.po_no?.toLowerCase().includes(query) ||
      item?.po_status?.toLowerCase().includes(query)
    );
    setFilterData(filteredData);
  };

  return (
    <div>
      <Header />
      <Page>
        <Row className='mb-3'>
          <Col xs={9} className='mb-1'>
            <Title pageTitle="PO List" />
          </Col>
          <Col xs={3} className='mb-1 text-end'>
            <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input
                size="sm"
                type="text"
                placeholder='Search'
                onChange={filter_data_handler}
              />
            </InputGroup>
          </Col>
        </Row>
        <Card color="primary" outline className='mb-3'>
          <CardBody>
            <Row>
              <Col md={12} className='mb-2'>
                <DataTable
                  className='mb-3'
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "50px"
                    },
                    {
                      name: 'PO Date',
                      selector: row => row.po_date,
                      wrap: true
                    },
                    {
                      name: 'PO No',
                      selector: row => row.po_no,
                      wrap: true
                    },
                    {
                      name: 'Created By',
                      selector: row => row.created_by,
                    },
                    {
                      name: 'PO Status',
                      selector: row => row.po_status,
                      wrap: true,
                    },
                    {
                      name: 'Total Quantity',
                      selector: row => row.quantity,
                    },
                    {
                      name: 'Action',
                      selector: row => row.action,
                    },
                  ]}
                  data={filterData}
                  highlightOnHover
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                  progressPending={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Page>
      <Footer />
    </div>
  );
};

export default POList;